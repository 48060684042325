import { saveToSStore } from '../persistence/storage';
import { DATA_STORAGE_KEY as STORAGE_KEY } from './context';

export const initialState = {
  rehydrated: false,
  accessToken: '',
  refreshToken: '',
  username: '',
  clientName: '',
  scopes: null,
};

export const authReducer = (state, { type, payload }) => {
  switch (type) {
    case 'init': {
      const nextState = { ...payload };
      saveToSStore(nextState, STORAGE_KEY);
      return nextState;
    }
    case 'rehydrated': {
      return { ...state, rehydrated: true };
    }
    case 'loginSucceeded': {
      const { access, refresh, username, clientName, scopes } = payload;
      const nextState = {
        ...state,
        accessToken: access,
        refreshToken: refresh,
        username,
        clientName,
        scopes,
      };

      saveToSStore(nextState, STORAGE_KEY);
      return nextState;
    }
    case 'logout': {
      const nextState = { ...initialState, rehydrated: true };
      saveToSStore(nextState, STORAGE_KEY);
      return nextState;
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
