import { useCallback } from 'react';

export default function useAuthActions(dispatch) {
  const loginSucceeded = useCallback(
    ({ access, refresh, username, clientName, scopes }) => {
      dispatch({
        type: 'loginSucceeded',
        payload: {
          access,
          refresh,
          username,
          clientName,
          scopes,
        },
      });
    },
    [dispatch]
  );
  const logout = useCallback(() => {
    dispatch({
      type: 'logout',
      payload: {},
    });
  }, [dispatch]);

  return {
    loginSucceeded,
    logout,
  };
}
