import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

//import { usePathname } from 'src/routes/hooks';

import Header from './header';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  //const pathname = usePathname();

  // const homePage = pathname === '/';

  return (
    <>
      <Header />

      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          bgcolor: 'grey.200',
          pb: 15,
          pt: 15,
          //height: '100%',
          // ...(!homePage && {
          //   pt: { xs: 8, md: 10 },
          // }),
        }}
      >
        {children}
      </Box>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
