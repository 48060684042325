// import { patternDotsDef, patternSquaresDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useData } from 'src/services/data/useData';
import colors from 'src/styles/colors';
import { chartTheme, chartTickPadding } from 'src/styles/theme';
import { getMatrixMaxValue, getMatrixMinValue } from 'src/utils/utils';
import CircularProgress from '../../CircularProgress/CircularProgress';

const formatData = (data, id) => {
  const _id = `${id}-${new Date().toString()}`;
  return [
    {
      id: _id,
      data: data?.data.map((item) => ({
        id: `${_id}-${item.time.toISOString()}`,
        x: item.time,
        y: item.value,
      })),
      stats: data?.stats,
    },
  ];
};

const ValueTooltip = ({ point }) => {
  return (
    <BasicTooltip
      id={
        <span>
          Date: <strong>{moment.utc(point.data.x).format('DD/MM/YYYY')}</strong>
          , Value: <strong>{point.data.y.toFixed(2)}</strong>
        </span>
      }
      enableChip={true}
      color={point.serieColor}
    />
  );
};

const ChartCommodity = ({
  filteredData,
  commodityName,
  setValuesOverview,
  valuesOverview,
  periodId = '7D',
  loading,
  timeSerieUnit,
}) => {
  const dataSetId = useMemo(
    () => `${commodityName}-${periodId}-${filteredData?.data.length}`,
    [commodityName, periodId, filteredData]
  );

  const chartData = useMemo(
    () => formatData(filteredData, dataSetId),
    [filteredData, dataSetId]
  );
  const maxValue = chartData[0].stats?.max;
  const minValue = chartData[0].stats?.min;
  const average = chartData[0].stats?.mean;
  const standardDeviation = chartData[0].stats?.volatility;
  const endDate = useMemo(
    () => getMatrixMaxValue(chartData[0].data, 'x'),
    [chartData]
  );
  const startDate = useMemo(
    () => getMatrixMinValue(chartData[0].data, 'x'),
    [chartData]
  );

  // Compute optimal tick values
  const numDays = moment
    .duration(moment(endDate).diff(moment(startDate)))
    .asDays();
  let tickValues = 'every year';

  if (window.innerWidth <= 1500) {
    if (numDays <= 4) {
      tickValues = 'every  day';
    } else if (numDays <= 15) {
      tickValues = 'every  week';
    } else if (numDays <= 30) {
      tickValues = 'every 2 week';
    } else if (numDays <= 90) {
      tickValues = 'every month';
    } else if (numDays <= 720) {
      tickValues = 'every 3 month';
    } else tickValues = 'every 3 year';
  } else {
    if (numDays <= 16) {
      tickValues = 'every day';
    } else if (numDays <= 90) {
      tickValues = 'every week';
    } else if (numDays <= 720) {
      tickValues = 'every month';
    }
  }

  useEffect(() => {
    if (
      valuesOverview.min !== minValue ||
      valuesOverview.max !== maxValue ||
      valuesOverview.standardDeviation !== standardDeviation
    ) {
      setValuesOverview({
        min: minValue,
        max: maxValue,
        average: average,
        standardDeviation: standardDeviation,
      });
    }
  }, [
    chartData,
    valuesOverview,
    setValuesOverview,
    maxValue,
    minValue,
    average,
    standardDeviation,
  ]);

  const { currency } = useData();

  const isLegend = false;
  const yScaleMax = maxValue + maxValue * 0.002; // maxValue + 0,2%
  const yScaleMin = minValue - minValue * 0.002; // minValue - 0,2%

  if (!filteredData?.data.length || loading) {
    return <CircularProgress absoluteCenter />;
  }

  return (
    <div style={{ height: '225px' }}>
      <ResponsiveLine
        data={chartData}
        curve="monotoneX"
        colors={colors.primary}
        lineWidth={3}
        isInteractive
        height={225}
        useMesh
        enablePoints={false}
        enableCrosshair={false}
        enableGridX
        enableGridY
        axisTop={null}
        axisRight={null}
        animate={false}
        xScale={{
          type: `time`,
          format: '%Y-%m-%d',
        }}
        yScale={{ type: 'linear', min: yScaleMin, max: yScaleMax }}
        axisBottom={{
          tickValues: tickValues,
          format: (value) => {
            return moment.utc(value).format('DD/MM/YYYY');
          },
          tickPadding: chartTickPadding,
        }}
        axisLeft={{
          tickPadding: chartTickPadding,
          format: (f) => f.toFixed(2),
          legend: timeSerieUnit?.unit || currency?.shortcode,
          legendOffset: -55,
          legendPosition: 'middle',
        }}
        legends={
          isLegend
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
            : undefined
        }
        margin={{
          top: 10,
          bottom: 30,
          left: 70,
          right: isLegend ? 100 : 30,
        }}
        theme={chartTheme}
        tooltip={ValueTooltip}
      />
    </div>
  );
};

export default ChartCommodity;
