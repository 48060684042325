import { periods } from '../../screens/markets/rawData/explore/components/Content/Content';
import { toggleInArray } from 'src/utils/utils';

const initialTabIndex = periods.indexOf(periods.find((p) => p.id === 'all'));
const defaultPeriod = periods[initialTabIndex];
const defaultTab = {
  id: 1,
  name: 'Dashboard 1',
  selectedItems: [], // items format: { id: 123, chartType: "bar" || "line "}
  periodIndex: initialTabIndex,
  period: defaultPeriod,
};

export const initialState = {
  tabs: [],
  tabsByContracts: {
    // [contractId]: tabs
  },
  selectedTabId: null,
  renameTabId: 0,
};

export const exploreReducer = (
  state,
  { type, payload, userId, storageCallback }
) => {
  switch (type) {
    case 'setFirstTab': {
      const { contractId } = payload;
      const nextTabsByContracts = { ...state.tabsByContracts };
      const prevTabs = nextTabsByContracts[contractId];

      let _selectedTabId = null;
      if (!nextTabsByContracts[contractId]) {
        nextTabsByContracts[contractId] = [defaultTab];
        _selectedTabId = defaultTab.id;
      } else {
        _selectedTabId = prevTabs[0].id;
      }

      const nextState = {
        ...state,
        tabsByContracts: nextTabsByContracts,
        selectedTabId: _selectedTabId,
        json_schema_version: 1,
      };

      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    case 'createTab': {
      const { contractId } = payload;
      const nextTabsByContracts = { ...state.tabsByContracts };
      const prevTabs = nextTabsByContracts[contractId];

      const nextTabId = prevTabs?.length
        ? prevTabs[prevTabs.length - 1].id + 1
        : 1;
      const nextTabName = `Dashboard ${nextTabId}`;

      const createdTab = {
        ...defaultTab,
        id: nextTabId,
        name: nextTabName,
      };

      const nextTabs = [...prevTabs, createdTab];

      nextTabsByContracts[contractId] = nextTabs;

      const nextState = {
        ...state,
        tabsByContracts: nextTabsByContracts,
        selectedTabId: createdTab.id,
        renameTabId: 0,
        json_schema_version: 1,
      };
      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    case 'deleteTab': {
      const { contractId, tabId } = payload;
      const nextTabsByContracts = { ...state.tabsByContracts };
      const prevTabs = nextTabsByContracts[contractId];

      const deletedTab = prevTabs.find((e) => e.id === tabId);
      const deletedTabIndex = prevTabs.indexOf(deletedTab);

      const nextTabs = prevTabs.filter((tab) => tab.id !== tabId);

      let nextTabId;
      if (deletedTabIndex < prevTabs.length - 1) {
        nextTabId = prevTabs[deletedTabIndex + 1].id;
      } else {
        nextTabId = prevTabs[deletedTabIndex - 1].id;
      }

      nextTabsByContracts[contractId] = nextTabs;

      const nextState = {
        ...state,
        tabsByContracts: nextTabsByContracts,
        selectedTabId: nextTabId,
        renameTabId: 0,
        json_schema_version: 1,
      };
      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    case 'toggleRenameTabMode': {
      const { tabId } = payload;
      const nextState = {
        ...state,
        renameTabId: tabId,
      };

      // saveToSStore(nextState, STORAGE_KEY);
      return nextState;
    }
    case 'renameTab': {
      const { contractId, tabId, name } = payload;
      const nextTabsByContracts = { ...state.tabsByContracts };

      const prevTab = nextTabsByContracts[contractId].find(
        (tab) => tab.id === tabId
      );

      const updatedTab = {
        ...prevTab,
        name,
      };

      const prevTabs = nextTabsByContracts[contractId];

      const nextTabs = prevTabs.map((tab) => {
        if (tab.id === tabId) {
          return updatedTab;
        }
        return tab;
      });

      nextTabsByContracts[contractId] = nextTabs;
      const nextState = {
        ...state,
        tabsByContracts: nextTabsByContracts,
        renameTabId: 0,
        json_schema_version: 1,
      };

      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    case 'setSelectedTabId': {
      const { contractId, tabId } = payload;

      const nextTabsByContracts = { ...state.tabsByContracts };
      const prevTabs = nextTabsByContracts[contractId];
      const tab = prevTabs.find((tab) => tab.id === tabId);

      if (tab === undefined) {
        return { ...state };
      }

      const nextState = {
        ...state,
        selectedTabId: tabId,
        renameTabId: state.renameTabId === tabId ? state.renameTabId : 0,
        json_schema_version: 1,
      };

      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    case 'toggleElement': {
      const { contractId, tabId, item } = payload;

      const nextTabsByContracts = { ...state.tabsByContracts };
      const prevTabs = nextTabsByContracts[contractId];

      const tab = prevTabs.find((tab) => tab.id === tabId);
      const tabIndex = prevTabs.findIndex((tab) => tab.id === tabId);

      // Change items
      const prevSelectedIds = [...tab.selectedItems];
      const nextSelectedIds = toggleInArray([...prevSelectedIds], item, 'id');

      // Update the tab
      const updatedTab = {
        ...tab,
        selectedItems: nextSelectedIds,
      };

      // Update tabs
      const nextTabs = [...prevTabs];
      nextTabs[tabIndex] = updatedTab;

      nextTabsByContracts[contractId] = nextTabs;

      // Update state
      const nextState = {
        ...state,
        tabsByContracts: nextTabsByContracts,
        renameTabId: 0,
        json_schema_version: 1,
      };
      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    case 'onTabPeriodChange': {
      const { contractId, tabId, period, periodIndex } = payload;

      const nextTabsByContracts = { ...state.tabsByContracts };
      const prevTabs = nextTabsByContracts[contractId];

      const tab = prevTabs.find((tab) => tab.id === tabId);
      const tabIndex = prevTabs.findIndex((tab) => tab.id === tabId);

      // Update the tab
      const updatedTab = {
        ...tab,
        period,
        periodIndex,
      };

      // Update tabs
      const nextTabs = [...prevTabs];
      nextTabs[tabIndex] = updatedTab;

      nextTabsByContracts[contractId] = nextTabs;

      // Update state
      const nextState = {
        ...state,
        tabsByContracts: nextTabsByContracts,
        renameTabId: 0,
        json_schema_version: 1,
      };
      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    case 'reset': {
      const nextState = { ...initialState };
      nextState.json_schema_version = 1;
      storageCallback(userId, 'explore_setup', nextState);
      return nextState;
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
