import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

import NavSectionHorizontal from './horizontal/nav-section-horizontal';

// ----------------------------------------------------------------------

export default function NavDesktop({ data }) {
  return (
    <Stack
      component="nav"
      direction="row"
      spacing={5}
      sx={{ mr: 2.5, height: 1 }}
    >
      <NavSectionHorizontal data={data} />
    </Stack>
  );
}

NavDesktop.propTypes = {
  data: PropTypes.array,
};
