import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { formatTimeSeriesDetails } from 'src/services/data/format';
import colors from 'src/styles/colors';
import { fontWeights, getRem } from 'src/styles/fonts/font';
import { getStartFromEndWithPeriod } from 'src/utils/utils';
import Card from '../../Card/Card';
import Text from '../../Text/Text';
import ChartBar from '../ChartBar/ChartBar';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: getRem(18),
    fontWeight: fontWeights.Bold,
  },
}));

const ExploreFullChartProbabilityOfVariation = ({
  timeSerie,
  getTimeSerieData,
  endDate,
  periodId,
  initialStartDate,
  isDefaultStartDate,
}) => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  // componentDidMount: getTimeSerieData()
  useEffect(() => {
    getTimeSerieData(timeSerie.id).then((timeSerieDetails) => {
      setData(formatTimeSeriesDetails(timeSerieDetails).data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = useMemo(() => {
    let startDate = getStartFromEndWithPeriod(periodId, endDate);
    if (periodId === 'dateRange' && isDefaultStartDate) {
      startDate = getStartFromEndWithPeriod('7D', endDate);
    } else if (periodId === 'dateRange' && !isDefaultStartDate) {
      startDate = initialStartDate;
    }

    const dataFilteredFromStart = data?.filter(
      (item) => Date.parse(item.time) > startDate
    );
    return dataFilteredFromStart?.filter(
      (item) => Date.parse(item.time) <= endDate
    );
  }, [periodId, endDate, isDefaultStartDate, data, initialStartDate]);

  const filteredFormatedData = useMemo(() => {
    return filteredData?.map((item) => ({
      ...item,
      time: moment(item.time).format('DD/MM'),
    }));
  }, [filteredData]);

  return (
    <Card
      className="report-export"
      top={
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="space-between"
        >
          <Text className={classes.title}>{timeSerie.name}</Text>
        </Box>
      }
      bottom={
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <Text>...</Text>
        </Box>
      }
    >
      <ChartBar
        data={filteredFormatedData}
        periodId={periodId}
        keys={['value']}
        indexBy="time"
        colors={[colors.primary]}
      />
      {/* <Text>Not implemented yet</Text> */}
      {/* <ChartProbabilityOfVariation data={[]} /> */}
    </Card>
  );
};

export default ExploreFullChartProbabilityOfVariation;
