import { createContext, useEffect, useContext, useReducer } from 'react';
import { initialState, exploreReducer as reducer } from './reducer';
import { useData } from '../data/useData';

const ExploreStateContext = createContext(initialState);

const ExploreDispatchContext = createContext();

export const ExploreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { user, getUserSetting, setUserSetting } = useData();

  useEffect(() => {
    if (user?.id) {
      getUserSetting(user.id, 'explore_setup').then((setting) => {
        dispatch({
          type: 'setFirstTab',
          payload: setting,
          userId: user.id,
          storageCallback: setUserSetting,
        });
      });
    }
  }, [user, getUserSetting, setUserSetting]);

  return (
    <ExploreStateContext.Provider value={state}>
      <ExploreDispatchContext.Provider value={dispatch}>
        {children}
      </ExploreDispatchContext.Provider>
    </ExploreStateContext.Provider>
  );
};

export const useExploreState = () => {
  const context = useContext(ExploreStateContext);
  if (context === undefined)
    throw new Error('useExploreState must be used within a ExploreProvider');
  return context;
};

export const useExploreDispatch = () => {
  const context = useContext(ExploreDispatchContext);
  if (context === undefined)
    throw new Error('useExploreDispatch must be used within a ExploreProvider');
  return context;
};
