let url;
if (process.env.REACT_APP_API_SERVER === "universal") {
    const hostname = window.location.hostname;

    if (hostname.includes("localhost")) {
        url = "http://" + hostname + ":8000";
    }
    else {
        url = "https://api." + hostname;
    }
}
else {
    url = process.env.REACT_APP_API_SERVER;
}

export const API_URL = url;
