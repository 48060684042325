import { sortArrayOfObjectsByDateKey } from 'src/utils/utils';

// Avoid double runs (for the same production_date)
const cleanRuns = (runs) => {
  if (!runs) {
    return [];
  }

  const cleanedRuns = []; // without double production_date items
  const productionDates = []; // util only

  runs.forEach((run) => {
    const alreadyAdded = productionDates.find(
      (productionDate) => productionDate === run.production_date
    );
    if (alreadyAdded) {
      // If we already aded an item with the same production_date => replace it
      const index = cleanedRuns.findIndex(
        (r) => r.production_date === run.production_date
      );
      cleanedRuns[index] = run;
    } else {
      cleanedRuns.push(run);
      productionDates.push(run.production_date);
    }
  });

  return cleanedRuns;
};

export const formatHorizonName = (horizon_name) => {
  return horizon_name.replace('B', 'BD');
};

export const formatFlowHorizons = (flowHorizons) => {
  if (!flowHorizons?.length) {
    return [];
  }
  return flowHorizons.map((flow) => ({
    ...flow,
    label: formatHorizonName(flow.label),
  }));
};

export const formatRuns = (runs) => {
  if (!runs?.length) {
    return [];
  }
  const cleanedRuns = cleanRuns(runs); // avoid double runs (for the same production_date)
  const sortedRunsByProductionDate = sortArrayOfObjectsByDateKey(
    cleanedRuns,
    'production_date'
  );
  return sortedRunsByProductionDate;
};

export const buildContractsByCommodities = (
  contracts = [],
  commodities = []
) => {
  const contractsByCommodities = [];

  commodities.sort((commo_1, commo_2) =>
    commo_1.name > commo_2.name ? 1 : commo_2.name > commo_1.name ? -1 : 0
  );

  commodities.forEach((commo) => {
    contractsByCommodities.push({ ...commo, contracts: [] });
  });

  contracts.forEach((contract) => {
    const commoId = contract.underlying_commodity;
    const commoIndex = contractsByCommodities.findIndex(
      ({ id }) => id === commoId
    );

    if (commoIndex !== -1) {
      contractsByCommodities[commoIndex].contracts.push(contract);
    }
  });

  return contractsByCommodities;
};

export const getRunWithSameDateOrTheLastOne = (runs = [], productionDate) => {
  const newRunWithSameDate = runs.find(
    (r) => r.production_date === productionDate
  );

  const lastRun = runs[runs.length - 1];

  const run = newRunWithSameDate || lastRun;

  return run;
};
