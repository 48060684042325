import { createTheme, responsiveFontSizes } from '@mui/material';
import { adaptV4Theme } from '@mui/material/styles';
import colors from './colors';
import { fontWeights } from './fonts/font';

/**
 * Fonts are:
 *   - declared in "src/styles/fonts/font.css"
 *   - imported in "src/index.js"
 *   - fontFamily is declared in this file and in "src/index.css"
 */

export const defaultTheme = createTheme();

//TODO use V5 pattern instead of using adaptV4Theme function
let theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiCssBaseline: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
        },
      },
      MuiPickersDay: {
        root: {
          fontSize: '14px',
          color: colors.primary,

          '&.Mui-selected': {
            '&:focus': {
              backgroundColor: colors.secondary,
            },
            '&:hover': {
              backgroundColor: colors.primary,
            },
            backgroundColor: colors.secondary,
            fontWeight: fontWeights.Bold,
          },
          '&.Mui-disabled': {
            color: colors.primaryLight,
          },
        },
      },
    },
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: colors.primary,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.secondary.main,
        main: colors.secondary,
        // dark: will be calculated from palette.secondary.main,
        // contrastText: will be calculated to contrast with palette.secondary.main
      },
      common: {
        ...colors,
        cardBgColor: 'white',
        cardContentBgColor: colors.primaryLight2,
      },
      background: {
        default: colors.screenBgColor,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1920,
      },
    },
    typography: {
      // fontSize: 14, // ratio (default: 14)
      fontFamily: ['SourceSansPro'].join(','),
      button: {
        textTransform: 'none',
      },
      h1: {
        color: colors.primary,
        fontSize: '4rem', // default 6rem
      },
      h2: {
        color: colors.secondary,
        fontSize: '2rem', // default 3.75rem
      },
      h3: {
        color: colors.primary,
        fontSize: '1.8rem', // default 3rem
      },
      h4: {
        color: colors.primary,
        fontSize: '1.6rem', // default 2.125rem
      },
      h5: {
        color: colors.primary,
        fontSize: '1.2rem', // default 1.5rem
      },
      h6: {
        color: colors.primary,
        fontSize: '1.1rem', // default 1.25rem
      },
      subtitle1: {},
      subtitle2: {},
      body1: {
        color: colors.primary,
        fontSize: '1rem', // default 1rem
      },
      body2: {
        color: colors.primary,
        fontSize: '0.875rem', // default 0.875rem
      },
      caption: {},
      overline: {},
    },
  })
);

theme = responsiveFontSizes(theme);

export const chartTickPadding = 12;
export const chartTickOpacity = 0.7;
export const chartTickFontWeight = fontWeights.SemiBold;
export const chartTheme = {
  // background: '#ffffff',
  fontFamily: 'SourceSansPro',
  textColor: colors.primary,
  fontSize: 11,
  // grid: {
  //   line: {
  //     stroke: colors.primaryLightOp,
  //   },
  // },
  axis: {
    ticks: {
      // line: {
      //   stroke: 'red',
      //   strokeWidth: 1,
      // },
      text: {
        // stroke: colors.primary,
        // fontSize: getRem(11),
        fontWeight: chartTickFontWeight,
        opacity: chartTickOpacity,
      },
    },
  },
  // domain: {
  //   line: {
  //     stroke: 'red',
  //     strokeWidth: 1,
  //   },
  // },
};
export const colorPalette = [
  colors.TiffanyBlue,
  colors.Beer,
  colors.deepCerise,
  colors.mediumSlateBlue,
  colors.pastelGreen,
  colors.CrayolaBlue,
  colors.munsellYellow,
  colors.tawnyOrange,
  colors.spanishGreen,
  colors.pear,
  colors.dodgerBlue,
  colors.firebrick,
  colors.darkOrange,
  colors.teal,
  colors.lavender,
  colors.sienna,
  colors.mediumPurple,
  colors.chartreuse,
  colors.royalBlue,
  colors.darkOliveGreen,
  colors.rosyBrown,
  colors.deepSkyBlue,
  colors.crimson,
  colors.springGreen,
  colors.darkMagenta,
  colors.yellowGreen,
  colors.mediumTurquoise,
  colors.orangeRed,
  colors.darkViolet,
  colors.oliveDrab,
  colors.sandyBrown,
  colors.mediumSeaGreen,
  colors.purple,
  colors.limeGreen,
  colors.darkTurquoise,
  colors.chocolate,
  colors.forestGreen,
  colors.goldenrod,
  colors.darkSlateGray,
  colors.cornflowerBlue,
  colors.saddleBrown,
  colors.skyBlue,
  colors.steelBlue,
  colors.tan,
  colors.thistle,
  colors.tomato,
  colors.fuchsia,
  colors.lime,
  colors.darkGoldenrod,
  colors.blueSapphire,
  colors.goldenYellow,
  colors.hotPink,
  colors.lightSeaGreen,
  colors.redOrange,
  colors.paleGreen,
  colors.babyPink,
  colors.sandyBeach,
  colors.slateBlue,
  colors.amethyst,
  colors.wheat,
  colors.lemonYellow,
  colors.azure,
  colors.brickRed,
  colors.deepPink,
  colors.oliveGreen2,
  colors.pumpkin,
  colors.darkLavender,
  colors.lightTurquoise,
  colors.darkCyan,
  colors.coral,
];

export const graphColorPalette = [
  colors.flatFesh,
  colors.melonMelody,
  colors.livid,
  colors.spray,
  colors.paradiseGreen,
  colors.squashBlossom,
  colors.mandarinRed,
  colors.azraqBlue,
  colors.dupain,
  colors.auroraGreen,
  colors.icelandPoppy,
  colors.tomatoRed,
  colors.yueGuangLanBlue,
  colors.goodSamaritan,
  colors.waterFall,
  colors.carrotOrange,
  colors.jalapenoRed,
  colors.darkSaphire,
  colors.forestBlues,
  colors.reefEncounter,
];

export default theme;
