import { Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useCallback, useEffect, useState, useMemo } from 'react';

import clsx from 'clsx';
import {
  DropdownButton,
  DropdownButtonMenu,
  DropdownButtonMenuItem,
} from 'src/components/BasicButton/DropdownButton';
import Card from 'src/components/Card/Card';
import StatsSummary from 'src/components/Card/StatsSummary';
import CircularProgress from 'src/components/CircularProgress/CircularProgress';
import DateRange from 'src/components/DateRange/DateRange';
import NoDataInChart from 'src/components/NoDataInChart/NoDataInChart';
import SelectTabs from 'src/components/SelectTabs/SelectTabs';
import Text from 'src/components/Text/Text';
import { useData } from 'src/services/data/useData';
import { useSituation } from 'src/services/situation/useSituation';
import colors from 'src/styles/colors';
import { fontWeights, getRem } from 'src/styles/fonts/font';
import { getStartFromEndWithPeriod } from 'src/utils/utils';
import ChartCommodity from './ChartCommodity';

export const commidityChartIndicatorsData = [
  {
    label: 'Average',
    valueKey: 'average',
  },
  {
    label: 'Min.',
    valueKey: 'min',
  },
  {
    label: 'Max.',
    valueKey: 'max',
  },
  {
    label: 'Vol.',
    valueKey: 'standardDeviation',
  },
];

const useStyles = makeStyles(() => ({
  cardContent: {
    position: 'relative',
  },
  chip: {
    backgroundColor: '#999',
    borderRadius: 4,
    marginRight: 8,
  },
  chipLabel: {
    color: 'white',
  },
  checkboxLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    fontSize: getRem(18),
    fontWeight: fontWeights.Bold,
    width: '100%',
  },
  indicatorLabel: {
    fontSize: getRem(12),
    fontWeight: fontWeights.Regular,
    color: colors.textSecondary,
  },
  indicatorValue: {
    fontSize: getRem(16),
    fontWeight: fontWeights.Bold,
    marginTop: -5,
  },
  extraTooltip: {
    overflow: 'visible',
  },
}));

const _initialStartDate = moment.utc().subtract(1, 'months'); // last month
const _initialEndDate = moment.utc();

const periods = [
  {
    id: '7D',
    label: '7D',
  },
  {
    id: '30D',
    label: '30D',
  },
  {
    id: '90D',
    label: '90D',
  },
  {
    id: '1Y',
    label: '1Y',
  },
  {
    id: '5Y',
    label: '5Y',
  },
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'dateRange',
    label: 'Date range',
    isDateRange: true,
  },
];
// Define here which date range (her id) you want selected by default
const initialTabIndex = periods.indexOf(periods.find((p) => p.id === 'all'));
const defaultPeriod = periods[initialTabIndex];

const FullChartCommodity = ({
  titleFromDetailsPanel,
  timeSerieFromDetailsPanel,
}) => {
  const classes = useStyles();
  const {
    selectedContract,
    target,
    selectedRun,
    getTimeSerieDataAndStats,
    contractHasProcurementFlow,
  } = useData();
  const { getTimeSerieXlsx, selectedSituationDate } = useSituation();
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [openDateRangePopover, setOpenDateRangePopover] = useState();

  const timeSerieId =
    timeSerieFromDetailsPanel?.id ||
    selectedContract?.series ||
    selectedContract?.series_id;

  const title = titleFromDetailsPanel || selectedContract?.name;

  const regexExcelFile = /[^\w()]+/g;
  const prod_date = moment.utc(selectedRun?.production_date).format('YYYYMMDD');
  const excelFileName =
    (title + '_' + prod_date).replace(regexExcelFile, '') + '.xlsx';
  const commodityName = title;

  const [prevSelectedRun, setPrevSelectedRun] = useState(selectedRun);
  const [prevTimeSerieId, setPrevTimeSerieId] = useState(timeSerieId);

  const [prevPeriod, setPrevPeriod] = useState(null);
  const [prevStartDate, setPrevLastDate] = useState(null);
  const [{ initialStartDate, initialEndDate }, setInitialDateRange] = useState({
    initialStartDate: _initialStartDate,
    initialEndDate: _initialEndDate,
  });

  const [xlsxFile, setXlsxFile] = useState(null);

  // Get time serie xlsx
  useEffect(() => {
    (async () => {
      if (timeSerieId && selectedRun?.production_date) {
        const formattedDate = moment
          .utc(selectedRun?.production_date)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss[Z]');
        const xlsx = await getTimeSerieXlsx(timeSerieId, formattedDate);
        setXlsxFile(xlsx);
      }
    })();
  }, [
    getTimeSerieXlsx,
    selectedRun?.production_date,
    selectedSituationDate,
    timeSerieId,
  ]);

  /**
   * Fetched data
   */

  const [timeSerieData, setTimeSerieData] = useState({ data: [] });
  const [isFecthing, setIsFetching] = useState(false);

  const lastDateAvailable = useMemo(() => {
    return Object.keys(target).length
      ? moment.utc(target?.data[target?.data.length - 1].time)
      : moment.utc().startOf('day');
  }, [target]);

  const fetchTimeSerie = useCallback(
    (period = defaultPeriod, dateRange) => {
      setIsFetching(true);

      if (dateRange) {
        getTimeSerieDataAndStats(
          timeSerieId,
          moment.utc(dateRange.startDate).format('YYYY-MM-DD'),
          moment.utc(dateRange.endDate).format('YYYY-MM-DD')
        ).then((timeSerieDetails) => {
          setTimeSerieData(timeSerieDetails);
        });
      } else {
        let periodId = period.id;

        if (period.isDateRange) {
          periodId = prevPeriod?.id || defaultPeriod.id;
        }

        const endDate = contractHasProcurementFlow
          ? moment.utc(selectedRun?.production_date).format('YYYY-MM-DD')
          : moment.utc(lastDateAvailable).format('YYYY-MM-DD');

        const startDate = getStartFromEndWithPeriod(periodId, endDate).format(
          'YYYY-MM-DD'
        );

        getTimeSerieDataAndStats(timeSerieId, startDate, endDate).then(
          (timeSerieDetails) => {
            setTimeSerieData(timeSerieDetails);
          }
        );
      }
      setIsFetching(false);
    },
    [
      contractHasProcurementFlow,
      getTimeSerieDataAndStats,
      prevPeriod?.id,
      selectedRun?.production_date,
      timeSerieId,
      lastDateAvailable,
    ]
  );

  /**
   * Left values overview
   */

  const [valuesOverview, _setValuesOverview] = useState({
    min: 0,
    max: 0,
    average: 0,
    standardDeviation: 0,
  });
  const setValuesOverview = useCallback((values) => {
    _setValuesOverview((prevValuesOverview) => ({
      ...prevValuesOverview,
      ...values,
    }));
  }, []);

  /**
   * Perdiods & DateRange
   */

  const onValidateDateRange = useCallback(
    (startDate, endDate) => {
      setInitialDateRange({
        initialStartDate: startDate,
        initialEndDate: endDate,
      });
      fetchTimeSerie(selectedPeriod, { startDate, endDate });
    },
    [fetchTimeSerie, selectedPeriod]
  );

  const onPeriodClicked = useCallback(
    (sectionName, periodIndex, item) => {
      const period = periods[periodIndex];

      const wasDateRangeBefore = prevPeriod?.id === 'dateRange';

      let start = prevStartDate;
      const end = wasDateRangeBefore
        ? initialEndDate
        : new moment.utc(selectedRun?.production_date);

      if (item.isDateRange) {
        if (!start) {
          start = end.subtract(7, 'days');
        } else {
          start = wasDateRangeBefore
            ? prevStartDate
            : getStartFromEndWithPeriod(prevPeriod.id, end);
        }

        openDateRangePopover();
      } else {
        start = getStartFromEndWithPeriod(period.id, end);
        fetchTimeSerie(period);
      }

      setPrevPeriod(period);

      setPrevLastDate(start);
      setInitialDateRange({
        initialStartDate: start,
        initialEndDate: end,
      });
      setSelectedPeriod(period);
    },
    [
      prevStartDate,
      initialEndDate,
      selectedRun?.production_date,
      openDateRangePopover,
      prevPeriod,
      fetchTimeSerie,
    ]
  );

  const onRunChange = useCallback(
    (run) => {
      const period = selectedPeriod || defaultPeriod;

      const end = new moment.utc(run?.production_date);
      const start = period.isDateRange
        ? initialStartDate
        : getStartFromEndWithPeriod(period.id, end);

      setInitialDateRange({
        initialStartDate: start,
        initialEndDate: end,
      });

      fetchTimeSerie(selectedPeriod, { startDate: start, endDate: end });
    },
    [selectedPeriod, initialStartDate, fetchTimeSerie]
  );

  /**
   * Effects
   */

  useEffect(() => {
    if (target && !selectedPeriod && typeof timeSerieId === 'number') {
      fetchTimeSerie(defaultPeriod);
      setSelectedPeriod(defaultPeriod);
      setPrevPeriod(defaultPeriod.id);
    }
  }, [target, selectedPeriod, fetchTimeSerie, timeSerieId]);

  useEffect(() => {
    if (selectedRun) {
      if (selectedRun !== prevSelectedRun) {
        onRunChange(selectedRun);
      }
      setPrevSelectedRun(selectedRun);
    }
  }, [onRunChange, prevSelectedRun, selectedRun]);

  // When timeSerieId changes
  useEffect(() => {
    if (selectedPeriod && timeSerieId !== prevTimeSerieId) {
      if (selectedPeriod.isDateRange) {
        const end = moment.utc(selectedRun.production_date);
        const start = selectedPeriod.isDateRange
          ? initialStartDate
          : getStartFromEndWithPeriod(selectedPeriod.id, end);

        fetchTimeSerie(selectedPeriod, { startDate: start, endDate: end });
      } else {
        fetchTimeSerie(selectedPeriod);
      }
      setPrevTimeSerieId(timeSerieId);
    } else if (timeSerieId && !prevTimeSerieId) {
      setPrevTimeSerieId(timeSerieId);
    }
  }, [
    prevTimeSerieId,
    timeSerieId,
    fetchTimeSerie,
    selectedPeriod,
    selectedRun,
    initialStartDate,
  ]);

  const loading = !target?.id || isFecthing;
  const noDataToDisplay = !loading && !timeSerieData?.data?.length;
  const statsData = commidityChartIndicatorsData.map(({ label, valueKey }) => {
    return {
      label,
      value: `${valuesOverview[valueKey]?.toFixed(2) || '?'}${
        valueKey === 'standardDeviation' ? '%' : ''
      }`,
    };
  });

  return (
    <Card
      className={clsx(classes.extraTooltip, 'report-export')}
      contentClassName={classes.cardContent}
      top={
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="space-between"
          id="target-daterange-popover-commodity-line"
        >
          <Text className={classes.title}>{title}</Text>
          <Box display="flex" flexShrink="0">
            <SelectTabs
              sectionName=""
              tabs={periods}
              initialTabIndex={initialTabIndex}
              tabsTitle=""
              onTabClicked={onPeriodClicked}
              DateRangeComponent={
                <DateRange
                  className={classes.dateRangeButton}
                  onValidate={onValidateDateRange}
                  popoverTarget="target-daterange-popover-commodity-line"
                  setOpenPopover={setOpenDateRangePopover}
                  initialStartDate={initialStartDate}
                  initialEndDate={initialEndDate}
                />
              }
            />
            <DropdownButton label="Actions">
              <DropdownButtonMenu>
                <Link
                  href={xlsxFile?.url}
                  download={excelFileName}
                  underline="none"
                >
                  <DropdownButtonMenuItem>
                    Export data history as XLSX
                  </DropdownButtonMenuItem>
                </Link>
              </DropdownButtonMenu>
            </DropdownButton>
          </Box>
          {/* <FormControlLabel
              control={<Checkbox name="checkedA" />}
              label="Forecasts Historic"
              className={classes.checkboxLabel}
            /> */}
        </Box>
      }
      left={!noDataToDisplay && <StatsSummary data={statsData} />}
    >
      {noDataToDisplay ? (
        <NoDataInChart />
      ) : (
        <>
          {!target || loading ? (
            <CircularProgress absoluteCenter size={20} />
          ) : (
            <ChartCommodity
              filteredData={timeSerieData}
              commodityName={commodityName}
              setValuesOverview={setValuesOverview}
              valuesOverview={valuesOverview}
              periodId={selectedPeriod?.id || defaultPeriod?.id}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default FullChartCommodity;
