import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import imageLine from 'src/assets/images/charts/line.svg';
import clsx from 'clsx';
import Modal from 'src/components/Modal/Modal';
import Text from 'src/components/Text/Text';
import { useCallback, useState } from 'react';
// import imageBar from 'assets/images/charts/bar.svg';
// import imagePie from 'assets/images/charts/pie.svg'; // Note: not in MVP
import closeIcon from 'src/assets/images/close.svg';
import Br from 'src/components/Br/Br';
import Card from 'src/components/Card/Card';
import colors from 'src/styles/colors';

const useStyles = makeStyles(() => ({
  root: {
    '& > div > div': {
      padding: 0,
      borderRadius: 12,
    },
  },
  paper: {
    maxWidth: 'unset',
    textAlign: 'center',
    backgroundColor: colors.light,
  },
  content: {
    padding: '76px 100px',
    borderRadius: 12,
  },
  modalButton: {
    borderRadius: 12,
    borderWidth: 2.5,
    padding: '13px 41px',
  },
  chartImageContainer: {
    padding: '50px 30px',
    display: 'flex',
    backgroundColor: colors.light,
    borderRadius: 12,
    border: '2px solid transparent',
  },
  chartImageContainerActive: {
    border: `1.5px solid ${colors.secondary}`,
  },
  chartImage: {
    width: 120,
    margin: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: 40,
    right: 40,
    cursor: 'pointer',
  },
}));

export default function ModalChartSelector({ open, toggleModal, onValidate }) {
  const classes = useStyles();

  const [selectedChartType, setSlectedChartType] = useState(''); // "bar" || "line"

  const onChartClicked = useCallback(
    (type) => () => {
      setSlectedChartType(type);
    },
    [setSlectedChartType]
  );

  const handleClose = useCallback(() => {
    if (open) {
      setSlectedChartType(null);
    }
    toggleModal();
  }, [open, setSlectedChartType, toggleModal]);

  const handleValidate = useCallback(() => {
    onValidate(selectedChartType);
    setSlectedChartType(null);
  }, [selectedChartType, onValidate]);

  return (
    <Modal
      classes={{
        root: classes.root,
        container: classes.root,
        paper: classes.paper,
      }}
      // className={classes.root}
      handleClose={handleClose}
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      // title="SELECT YOUR GRAPH TYPE"
    >
      <Card contentClassName={classes.content}>
        <img
          src={closeIcon}
          alt="Close icon"
          className={classes.closeIcon}
          onClick={handleClose}
        />
        <Text size={18} bold>
          Select your graph type
        </Text>
        <Br height={34} />
        <Grid container>
          {/* <Button
            onClick={onChartClicked('pie')}
            // variant={selectedChartType === 'pie' ? 'outlined' : undefined}
            className={clsx(classes.chartImageContainer, {
              [classes.chartImageContainerActive]: selectedChartType === 'pie',
            })}
          >
            <div>
              <img
                src={imagePie}
                alt="Pie chart"
                className={classes.chartImage}
              />
              <Text size={14} weight="SemiBold">
                Pie chart
              </Text>
            </div>
          </Button>
          <Br width={20} />
          <Button
            onClick={onChartClicked('bar')}
            className={clsx(classes.chartImageContainer, {
              [classes.chartImageContainerActive]: selectedChartType === 'bar',
            })}
          >
            <div>
              <img
                src={imageBar}
                alt="Bar chart"
                className={classes.chartImage}
              />
              <Text size={14} weight="SemiBold">
                Bar chart
              </Text>
            </div>
          </Button>
          <Br width={20} />
           */}
          <Button
            onClick={onChartClicked('line')}
            className={clsx(classes.chartImageContainer, {
              [classes.chartImageContainerActive]: selectedChartType === 'line',
            })}
          >
            <div>
              <img
                src={imageLine}
                alt="Line chart"
                className={classes.chartImage}
              />
              <Text size={14} weight="SemiBold">
                Line chart
              </Text>
            </div>
          </Button>
        </Grid>
        <Br height={30} />
        <Button
          variant="outlined"
          onClick={handleValidate}
          disabled={!selectedChartType}
          color="secondary"
          className={classes.modalButton}
        >
          <Text
            color={selectedChartType ? 'secondary' : 'grey'}
            size={16}
            weight="SemiBold"
          >
            Apply
          </Text>
        </Button>
      </Card>
    </Modal>
  );
}
