import { Navigate, useRoutes } from 'react-router-dom';
import { useAuth } from 'src/services/auth/useAuth';

//import { PATH_AFTER_LOGIN } from 'src/config-global';

import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import jwt_decode from 'jwt-decode';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function Router() {
  const { accessToken } = useAuth();
  const accessTokenScope = accessToken && jwt_decode(accessToken).scope;
  const hasRecommendationsAccess = accessTokenScope?.includes(
    'recommendations::read'
  );
  const hasSituationAccess = accessTokenScope?.includes('situation::read');
  const hasMarketAccess = accessTokenScope?.includes('market-trends::read');

  let PATH_AFTER_LOGIN;

  if (accessToken) {
    let CUSTOM_PATH_AFTER_LOGIN;
    if (hasSituationAccess) {
      CUSTOM_PATH_AFTER_LOGIN = paths.dashboard.reporting;
    } else if (hasMarketAccess) {
      CUSTOM_PATH_AFTER_LOGIN = paths.dashboard.market.root;
    } else if (hasRecommendationsAccess) {
      CUSTOM_PATH_AFTER_LOGIN = paths.dashboard.decision.root;
    } else {
      // If user has no access at all, navigate to 403 page
      CUSTOM_PATH_AFTER_LOGIN = '403';
    }

    PATH_AFTER_LOGIN = CUSTOM_PATH_AFTER_LOGIN;
  } else {
    PATH_AFTER_LOGIN = paths.dashboard.root;
  }

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Gitlab preview route
    {
      path: '/-/web-ui/-/jobs/:jobId/artifacts/build-dev/index.html',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
