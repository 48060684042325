import { useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';

export const DEFAULT_GROUP_ID = 0;

export default function useAuthQueries() {
  const register = useCallback(
    ({ company, first_name, last_name, email, username, password }) => {
      const url = `${API_URL}/account/register/`;
      return axios.post(url, {
        company,
        first_name,
        last_name,
        email,
        username,
        password,
      });
    },
    []
  );

  const sendResetPasswordLink = useCallback(({ login }) => {
    const url = `${API_URL}/account/send-reset-password-link/`;
    return axios.post(url, { login });
  }, []);

  const resetPassword = useCallback(
    ({ user_id, timestamp, signature, password }) => {
      const url = `${API_URL}/account/reset-password/`;
      return axios.post(url, { user_id, timestamp, signature, password });
    },
    []
  );

  const login = useCallback(({ username, password }) => {
    const url = `${API_URL}/auth/token/`;
    return axios.post(url, { username, password });
  }, []);

  return {
    register,
    sendResetPasswordLink,
    resetPassword,
    login,
  };
}
