import { createContext, useEffect, useContext, useReducer } from 'react';
import { initialState, authReducer as reducer } from './reducer';
import {
  getFromSStoreOrDefault,
  STORAGE_KEY_PREFIX,
} from '../persistence/storage';

export const AuthStateContext = createContext(initialState);

const AuthDispatchContext = createContext();

export const DATA_STORAGE_KEY = `${STORAGE_KEY_PREFIX}AUTH`;

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    getFromSStoreOrDefault(initialState, DATA_STORAGE_KEY).then((defState) => {
      if (JSON.stringify(defState) !== JSON.stringify(initialState)) {
        dispatch({ type: 'init', payload: defState });
      }
      dispatch({ type: 'rehydrated', payload: {} });
    });
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined)
    throw new Error('useAuthState must be used within a AuthProvider');
  return context;
};

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined)
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  return context;
};
