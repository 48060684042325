export const getRem = (px) => {
  /**
   * Important: this conversion is correct only if the <html> font-size
   * equals 16px or 100%.
   */

  const pxToRemMap = {
    10: '0.625rem',
    11: '0.6875rem',
    12: '0.75rem',
    13: '0.8125rem',
    14: '0.875rem',
    15: '0.9375rem',
    16: '1rem',
    17: '1.0625rem',
    18: '1.125rem',
    19: '1.1875rem',
    20: '1.25rem',
    21: '1.3125rem',
    22: '1.375rem',
    23: '1.4375rem',
    24: '1.5rem',
    25: '1.5625rem',
    26: '1.625rem',
    27: '1.6875rem',
    28: '1.75rem',
    29: '1.8125rem',
    30: '1.875rem',
    31: '1.9375rem',
    32: '2rem',
    33: '2.0625rem',
    34: '2.125rem',
    35: '2.1875rem',
    36: '2.25rem',
    37: '2.3125rem',
    38: '2.375rem',
    39: '2.4375rem',
    40: '2.5rem',
    41: '2.5625rem',
    42: '2.625rem',
    43: '2.6875rem',
    44: '2.75rem',
    45: '2.8125rem',
    46: '2.875rem',
    47: '2.9375rem',
    48: '3rem',
    49: '3.0625rem',
    50: '3.125rem',
    51: '3.1875rem',
    52: '3.25rem',
    53: '3.3125rem',
    54: '3.375rem',
    55: '3.4375rem',
    56: '3.5rem',
    57: '3.5625rem',
    58: '3.625rem',
    59: '3.6875rem',
    60: '3.75rem',
    61: '3.8125rem',
    62: '3.875rem',
    63: '3.9375rem',
    64: '4rem',
  };

  const rem = pxToRemMap[px];

  if (!rem) {
    console.warn('getRem(px): conversion not found', px);
  }

  return rem;
};

export const fontWeights = {
  ExtraLight: '200',
  Light: '300',
  Regular: '400',
  Italic: '400',
  SemiBold: '600',
  Bold: '700',
  Black: '900',
};
