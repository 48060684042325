import { createContext, useEffect, useContext, useReducer } from 'react';
import { initialState, recommendationsReducer as reducer } from './reducer';
import { useData } from '../data/useData';

const RecommendationsStateContext = createContext(initialState);

const RecommendationsDispatchContext = createContext();

export const RecommendationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { user } = useData();
  useEffect(() => {
    if (user?.id) {
      dispatch({
        type: 'setFirstTab',
      });
    }
  }, [user]);

  return (
    <RecommendationsStateContext.Provider value={state}>
      <RecommendationsDispatchContext.Provider value={dispatch}>
        {children}
      </RecommendationsDispatchContext.Provider>
    </RecommendationsStateContext.Provider>
  );
};

export const useRecommendationsState = () => {
  const context = useContext(RecommendationsStateContext);
  if (context === undefined)
    throw new Error(
      'useRecommendationsState must be used within a RecommendationsProvider'
    );
  return context;
};

export const useRecommendationsDispatch = () => {
  const context = useContext(RecommendationsDispatchContext);
  if (context === undefined)
    throw new Error(
      'useRecommendationsDispatch must be used within a RecommendationsProvider'
    );
  return context;
};
