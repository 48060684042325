import { Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  DropdownButton,
  DropdownButtonMenu,
  DropdownButtonMenuItem,
} from 'src/components/BasicButton/DropdownButton';
import Card from 'src/components/Card/Card';
import StatsSummary from 'src/components/Card/StatsSummary';
import CircularProgress from 'src/components/CircularProgress/CircularProgress';
import NoDataInChart from 'src/components/NoDataInChart/NoDataInChart';
import Text from 'src/components/Text/Text';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useData } from 'src/services/data/useData';
import { useSituation } from 'src/services/situation/useSituation';
import colors from 'src/styles/colors';
import { fontWeights, getRem } from 'src/styles/fonts/font';
import { getStartFromEndWithPeriod, isDev } from 'src/utils/utils';
import ChartCommodity from './ChartCommodity';
import { commidityChartIndicatorsData } from './FullChartCommodity';

const useStyles = makeStyles(() => ({
  cardContent: {
    position: 'relative',
  },
  chip: {
    backgroundColor: '#999',
    borderRadius: 4,
    marginRight: 8,
  },
  chipLabel: {
    color: 'white',
  },
  checkboxLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    fontSize: getRem(18),
    fontWeight: fontWeights.Bold,
  },
  indicatorLabel: {
    fontSize: getRem(12),
    fontWeight: fontWeights.Regular,
    color: colors.textSecondary,
  },
  indicatorValue: {
    fontSize: getRem(16),
    fontWeight: fontWeights.Bold,
    marginTop: -5,
  },
  extraTooltip: {
    overflow: 'visible',
  },
}));

// Improve drastically performances (avoid re-fetching on tab change)
const fetchHistory = {
  /**
   * [id-startDate-endDate]: {
   *   timeSerieDetails,
   *   data,
   * }
   */
};

const ExploreFullChartCommodity = ({
  timeSerie,
  selectedRun,
  selectedPeriod,
  prevPeriod,
  defaultPeriod,
  initialStartDate,
  initialEndDate,
  isDefaultStartDate,
}) => {
  const classes = useStyles();
  const { target, getTimeSerieDataAndStats, getTimeSeriesUnitById } = useData();
  const { getTimeSerieXlsx } = useSituation();

  // Inner ref to avoid double fetching
  const [innerPrevPeriodId, setInnerPrevPeriodId] = useState(null);
  const [innerPrevSelectedRunId, setInnerPrevSelectedRunId] = useState(null);

  const timeSerieId = timeSerie?.id;
  const commodityName = timeSerie?.display_name || timeSerie?.name;

  /**
   * Fetched data
   */

  const [timeSerieData, setTimeSerieData] = useState({ data: [] });
  const [isFecthing, setIsFetching] = useState(false);

  const fetchTimeSerie = useCallback(
    async (period, dateRange) => {
      const noRunSelected = typeof selectedRun?.id !== 'number';

      if (noRunSelected) {
        if (isDev) {
          console.log(
            'fetchTimeSerie: No run selected yet, skipping this fetchTimeSerie() call'
          );
        }
        return;
      }

      setIsFetching(true);

      // Get time serie unit
      if (timeSerieId && timeSerie.price_driver_name !== 'Energy prices') {
        const unit = await getTimeSeriesUnitById(timeSerieId);
        setTimeSerieUnit(unit);
      }

      if (dateRange) {
        const endDate = moment.utc(dateRange.endDate).format('YYYY-MM-DD');
        const startDate = moment.utc(dateRange.startDate).format('YYYY-MM-DD');
        const requestKey = `${timeSerieId}-${startDate}-${endDate}`;
        const alreadyFetched = Boolean(fetchHistory[requestKey]);

        // Avoid re-fetching if possible
        if (alreadyFetched) {
          const previousFetch = fetchHistory[requestKey];
          setTimeSerieData(previousFetch.timeSerieDetails);
          setIsFetching(false);
          return;
        }

        // Fetch data
        getTimeSerieDataAndStats(timeSerieId, startDate, endDate).then(
          (timeSerieDetails) => {
            // Remember the request for later
            fetchHistory[requestKey] = {
              timeSerieDetails,
            };

            // Set data
            setTimeSerieData(timeSerieDetails);
          }
        );
      } else {
        let _period = period.id;

        if (period?.isDateRange) {
          _period = prevPeriod || defaultPeriod.id;
        }

        const endDate = moment
          .utc(selectedRun?.production_date)
          .format('YYYY-MM-DD');
        const startDate = getStartFromEndWithPeriod(_period, endDate).format(
          'YYYY-MM-DD'
        );

        const requestKey = `${timeSerieId}-${startDate}-${endDate}`;
        const alreadyFetched = Boolean(fetchHistory[requestKey]);

        // Avoid re-fetching if possible
        if (alreadyFetched) {
          const previousFetch = fetchHistory[requestKey];
          setTimeSerieData(previousFetch.timeSerieDetails);
          setIsFetching(false);
          return;
        }

        // Fetch data
        getTimeSerieDataAndStats(timeSerieId, startDate, endDate).then(
          (timeSerieDetails) => {
            // Remember the request for later
            fetchHistory[requestKey] = {
              timeSerieDetails,
            };

            // Set data
            setTimeSerieData(timeSerieDetails);
          }
        );
      }

      setIsFetching(false);
    },
    [
      selectedRun?.id,
      selectedRun?.production_date,
      timeSerieId,
      timeSerie.price_driver_name,
      getTimeSeriesUnitById,
      getTimeSerieDataAndStats,
      prevPeriod,
      defaultPeriod.id,
    ]
  );

  const onRunChange = useCallback(
    (run) => {
      const end = new Date(run.production_date);

      let start = getStartFromEndWithPeriod(selectedPeriod.id, end);
      if (selectedPeriod.isDateRange && isDefaultStartDate) {
        start = getStartFromEndWithPeriod('7D', end);
      } else if (selectedPeriod.isDateRange && !isDefaultStartDate) {
        start = initialStartDate;
      }

      fetchTimeSerie(
        selectedPeriod,
        { startDate: start, endDate: end },
        'onRunChange'
      );
    },
    [selectedPeriod, initialStartDate, fetchTimeSerie, isDefaultStartDate]
  );

  const onDateRangeChange = useCallback(
    (startDate, endDate) => {
      fetchTimeSerie(
        selectedPeriod,
        { startDate, endDate },
        'onDateRangeChange'
      );
    },
    [selectedPeriod, fetchTimeSerie]
  );

  /**
   * Left values overview
   */

  const [valuesOverview, _setValuesOverview] = useState({
    min: 0,
    max: 0,
    average: 0,
    standardDeviation: 0,
  });

  const [xlsxFile, setXlsxFile] = useState(null);
  const [timeSerieUnit, setTimeSerieUnit] = useState(null);

  // Get time serie xlsx
  useEffect(() => {
    (async () => {
      if (timeSerieId) {
        const formattedDate = moment
          .utc(selectedRun?.production_date)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss[Z]');
        const xlsx = await getTimeSerieXlsx(timeSerieId, formattedDate);
        setXlsxFile(xlsx);
      }
    })();
  }, [getTimeSerieXlsx, selectedRun?.production_date, timeSerieId]);

  const setValuesOverview = useCallback((values) => {
    _setValuesOverview((prevValuesOverview) => ({
      ...prevValuesOverview,
      ...values,
    }));
  }, []);

  /**
   * Effects
   */

  // Effect: on period change
  useEffect(() => {
    if (selectedPeriod?.id && selectedPeriod.id !== innerPrevPeriodId) {
      const dateRange =
        selectedPeriod.isDateRange && !isDefaultStartDate
          ? {
              startDate: initialStartDate,
              endDate: initialEndDate,
            }
          : null;

      fetchTimeSerie(selectedPeriod, dateRange, 'onPeriodChange');
      setInnerPrevPeriodId(selectedPeriod?.id);
    }
  }, [
    selectedPeriod,
    isDefaultStartDate,
    fetchTimeSerie,
    innerPrevPeriodId,
    initialStartDate,
    initialEndDate,
  ]);

  // Effect: on run change
  useEffect(() => {
    if (
      typeof selectedRun?.id === 'number' &&
      selectedRun.id !== innerPrevSelectedRunId
    ) {
      onRunChange(selectedRun);
      setInnerPrevSelectedRunId(selectedRun?.id);
    }
  }, [
    onRunChange,
    setInnerPrevSelectedRunId,
    selectedRun,
    innerPrevSelectedRunId,
  ]);

  // Effect: on period/date-range change
  useEffect(() => {
    if (selectedPeriod?.isDateRange && !isDefaultStartDate) {
      onDateRangeChange(initialStartDate, initialEndDate);
    }
  }, [
    initialStartDate,
    initialEndDate,
    selectedPeriod,
    onDateRangeChange,
    isDefaultStartDate,
  ]);

  const loading = !target?.id || isFecthing;
  const noDataToDisplay = !loading && !timeSerieData?.data?.length;
  const title = timeSerie?.display_name || timeSerie?.name;

  const regexExcelFile = /[^\w()]+/g;
  const prod_date = moment.utc(selectedRun?.production_date).format('YYYYMMDD');
  const excelFileName =
    (title + '_' + prod_date).replace(regexExcelFile, '') + '.xlsx';

  const statsData = commidityChartIndicatorsData.map(({ label, valueKey }) => {
    return {
      label,
      value: `${valuesOverview[valueKey]?.toFixed(2) || '?'}${
        valueKey === 'standardDeviation' ? '%' : ''
      }`,
    };
  });

  return (
    <Card
      className={clsx(classes.extraTooltip, 'report-export')}
      contentClassName={classes.cardContent}
      top={
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="space-between"
        >
          <Text className={classes.title}>{title}</Text>
          <DropdownButton label="Actions">
            <DropdownButtonMenu>
              <Link
                href={xlsxFile?.url}
                download={excelFileName}
                underline="none"
              >
                <DropdownButtonMenuItem>
                  Export data history as XLSX
                </DropdownButtonMenuItem>
              </Link>
            </DropdownButtonMenu>
          </DropdownButton>
          {/* <FormControlLabel
              control={<Checkbox name="checkedA" />}
              label="Forecasts Historic"
              className={classes.checkboxLabel}
            /> */}
        </Box>
      }
      left={!noDataToDisplay && <StatsSummary data={statsData} />}
    >
      {noDataToDisplay ? (
        <NoDataInChart className={classes.noDataContainer} />
      ) : (
        <>
          {target ? (
            <ChartCommodity
              filteredData={timeSerieData}
              commodityName={commodityName}
              setValuesOverview={setValuesOverview}
              valuesOverview={valuesOverview}
              periodId={selectedPeriod?.id || defaultPeriod?.id}
              timeSerieUnit={timeSerieUnit}
            />
          ) : (
            <CircularProgress absoluteCenter />
          )}
        </>
      )}
    </Card>
  );
};

export default ExploreFullChartCommodity;
