import { paths } from 'src/routes/paths';

import Iconify from 'src/components/iconify';
//import Label from 'src/components/label';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Reporting',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.dashboard.reporting,
  },
  {
    title: 'Markets',
    path: paths.dashboard.market.root,
    icon: <Iconify icon="solar:file-bold-duotone" />,
  },
  {
    title: 'Decisions',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.dashboard.decision.root,
  },
];
export const NAV_ITEMS = [
  {
    subheader: 'Modules',
    items: [
      {
        title: 'Reporting',
        path: paths.dashboard.reporting,

        // caption: 'Display only admin role',
      },

      {
        title: 'Markets',
        path: paths.dashboard.market.root,

        children: [
          {
            title: 'Prices and volatility',
            path: paths.dashboard.market.pricesAndVolatility,
          },
          {
            title: 'Analyses and forecasts',
            path: paths.dashboard.market.analysesAndPredictions,
          },
          { title: 'Price drivers', path: paths.dashboard.market.priceDrivers },
          { divider: true, path: '#' },
          // { title: 'Scenarios', path: paths.dashboard.market.scenarios },
          { title: 'Raw Data', path: paths.dashboard.market.rawData },
        ],
      },
      {
        title: 'Decisions',
        path: paths.dashboard.decision.root,
        children: [
          {
            title: 'Configuration',
            path: paths.dashboard.decision.configuration,
          },
          { title: 'Program', path: paths.dashboard.decision.program },
          {
            title: 'Benchmarking',
            path: paths.dashboard.decision.benchmarking,
          },
        ],
      },
    ],
  },
];
