import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { createContext, useContext } from 'react';
import colors from 'src/styles/colors';
import Text from '../Text/Text';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    marginLeft: 15,
    marginRight: 15,
    padding: '5px 28px',
    borderRadius: 8,
    borderColor: colors.primary,
    color: colors.primary,
    backgroundColor: 'white',
    height: 37,
    minWidth: 100,
  },
  menu: {
    '& .MuiPaper-root': {
      borderRadius: 5,
    },
  },
}));
const DropdownButtonContext = createContext();
function DropdownButton({ children, label, ...restProps }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DropdownButtonContext.Provider
      value={{ anchorEl, setAnchorEl, handleClose }}
      {...restProps}
    >
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        endIcon={<ArrowDropDown />}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        {...restProps}
      >
        <Text weight="SemiBold" style={{ color: colors.primary }}>
          {label}
        </Text>
      </Button>
      {children}
    </DropdownButtonContext.Provider>
  );
}

function DropdownButtonMenu({ children, ...restProps }) {
  const classes = useStyles();
  const { anchorEl, handleClose } = useContext(DropdownButtonContext);

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClick={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      className={classes.menu}
      {...restProps}
    >
      {children}
    </Menu>
  );
}
const DropdownButtonMenuItem = React.forwardRef(
  ({ children, ...restProps }, ref) => {
    return (
      <MenuItem {...restProps} ref={ref}>
        {children}
      </MenuItem>
    );
  }
);
DropdownButtonMenuItem.displayName = 'DropdownButtonMenuItem';

export { DropdownButton, DropdownButtonMenu, DropdownButtonMenuItem };
