import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
//import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Icon = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  //const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const icon = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 23 24"
      >
        <path
          fill="#212B36"
          fillRule="evenodd"
          d="M0 23.979h1.384V12.735H0V23.98zm2.768 0h1.383V.062H2.768V23.98zm5.534 0h1.384V1.472H8.303v22.507zm-2.767 0H6.92v-21.1H5.535v21.1zM11.07.062v1.41c5.723 0 10.379 4.737 10.379 10.56 0 5.822-4.656 10.56-10.379 10.56V24c6.486 0 11.763-5.369 11.763-11.969C22.833 5.432 17.556.062 11.07.062z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#212B36"
          fillRule="evenodd"
          d="M13.147 12.031c0 .168-.02.33-.057.487l1.346.325c.06-.26.094-.533.094-.812 0-1.94-1.552-3.52-3.46-3.52V9.92c1.145 0 2.077.948 2.077 2.112zm5.534 0a7.793 7.793 0 01-.909 3.665l1.22.667a9.218 9.218 0 001.073-4.332c0-5.046-4.036-9.152-8.995-9.152v1.408c4.197 0 7.61 3.474 7.61 7.744z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#212B36"
          fillRule="evenodd"
          d="M15.914 12.031a4.951 4.951 0 01-1.988 3.978l.815 1.136a6.365 6.365 0 002.557-5.114c0-3.493-2.794-6.336-6.228-6.336v1.408c2.671 0 4.844 2.21 4.844 4.928z"
          clipRule="evenodd"
        ></path>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return icon;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {icon}
    </Link>
  );
});

Icon.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Icon;
