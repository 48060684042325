import moment from 'moment';
import { isDev } from 'src/utils/utils';

const tabs = [
  {
    id: 1,
    name: 'Program',
    containsChart: true,
  },
  {
    id: 2,
    name: 'Benchmarking',
    containsChart: true,
  },
  {
    id: 3,
    name: 'Configuration',
    containsChart: false,
  },
];

export const initialState = {
  tabs: tabs,
  selectedTabId: tabs[0].id,
  selectedTransactionsDate: moment(),
};
const defaultTab = {
  id: tabs[0].id,
  name: tabs[0].name,
};

export const recommendationsReducer = (state, { type, payload }) => {
  switch (type) {
    case 'setFirstTab': {
      let _selectedTabId = defaultTab.id;

      const nextState = {
        ...state,
        selectedTabId: _selectedTabId,
      };

      return nextState;
    }
    case 'setSelectedTabId': {
      const { tabId } = payload;

      const tab = tabs.find((tab) => tab.id === tabId);

      if (tab === undefined) {
        return { ...state };
      }

      const nextState = {
        ...state,
        selectedTabId: tabId,
        json_schema_version: 1,
      };

      return nextState;
    }
    case 'setMultiDataRecommendations': {
      const { data } = payload;

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!state.hasOwnProperty(key)) {
          if (isDev) {
            console.warn(
              `The data key "${key}" is not handled in src/services/recommendations/reducer.js`
            );
          }
        }
      });

      const nextState = {
        ...state,
        ...data,
      };
      return nextState;
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
