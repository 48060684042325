import PropTypes from 'prop-types';

import Header from '../common/header-simple';
import Box from '@mui/material/Box';
//import { usePathname } from 'src/routes/hooks';
// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
  //const pathname = usePathname();
  //const homePage = pathname === '/';
  return (
    <>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'grey.200',
          pb: 10,
          pt: 20,
          //height: '100%',
          // ...(!homePage && {
          //   pt: { xs: 8, md: 10 },
          // }),
        }}
      >
        {children}
      </Box>
    </>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
};
