import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

export const contentMaxWidth = 2000;

export const screenPaddings = (theme) => ({
  top: theme.spacing(8),
  bottom: theme.spacing(8),
  left: theme.spacing(6),
  right: theme.spacing(6),
});

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: theme.palette.common.screenBgColorColor,
    paddingTop: screenPaddings(theme).top,
    paddingBottom: props.noPaddingBottom ? 0 : screenPaddings(theme).bottom,
    paddingLeft: props.noPaddingHorizontal ? 0 : screenPaddings(theme).left,
    paddingRight: props.noPaddingHorizontal ? 0 : screenPaddings(theme).right,
    overflow: 'auto',
    height: '100vh',
  }),
  content: ({ noMaxWidth }) => ({
    maxWidth: noMaxWidth ? 'unset' : contentMaxWidth,
  }),
}));

export default function Screen(props) {
  const {
    children,
    className,
    contentClassName,

    contentContainerProps,

    ...otherProps
  } = props;
  const classes = useStyles(props);

  return (
    <Container
      className={clsx(classes.root, className)}
      maxWidth={false}
      disableGutters
      {...otherProps}
    >
      <Container
        maxWidth={false}
        className={clsx(classes.content, contentClassName)}
        {...contentContainerProps}
      >
        {children}
      </Container>
    </Container>
  );
}
