import {
  info,
  error,
  primary,
  success,
  warning,
  secondary,
} from 'src/theme/palette';

// ----------------------------------------------------------------------

export const CALENDAR_COLOR_OPTIONS = [
  primary.main,
  secondary.main,
  info.main,
  info.darker,
  success.main,
  warning.main,
  error.main,
  error.darker,
];
