import { CircularProgress as CircularProgressMUI } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const styles = makeStyles(() => ({
  root: ({ absoluteCenter }) => ({
    ...(absoluteCenter
      ? {
          position: 'absolute',
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }
      : {}),
  }),
}));

export default function CircularProgress(props) {
  const { className, size = 30, ...otherProps } = props;
  const classes = styles(props);

  return (
    <CircularProgressMUI
      className={clsx(classes.root, className)}
      size={size}
      {...otherProps}
    />
  );
}
