export const formatTarget = (target) => {
  return {
    ...target,
    data: target.data?.map((item) => {
      return {
        ...item,
        // Transform ISO 8601 dates to native Dates
        // There should be a way to parse directly ISO8601 dates from Nivo chart
        // TO BE INVESTIGATED
        time: new Date(item.time),
      };
    }),
  };
};

export const formatTimeSeriesDetails = (timeSerie) => {
  return {
    ...timeSerie,
    data: timeSerie.data?.map((item) => {
      return {
        ...item,
        // Transform ISO 8601 dates to native Dates
        // There should be a way to parse directly ISO8601 dates from Nivo chart
        // TO BE INVESTIGATED
        time: new Date(item.time),
      };
    }),
  };
};

export const outputsTypesMap = {
  'price-bands': 'bands',
  'price-corridors': 'corridor',
  'trends': 'trends',
  'feature-importances': 'featureImportances',
  'accuracies': 'accuracies',
};
