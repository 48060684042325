import { useCallback } from 'react';

export default function useDataActions(dispatch) {
  const setData = useCallback(
    ({ dataType, data }) => {
      dispatch({
        type: 'setData',
        payload: {
          dataType,
          data,
        },
      });
    },
    [dispatch]
  );

  const setMultiData = useCallback(
    (data) => {
      dispatch({
        type: 'setMultiData',
        payload: {
          data,
        },
      });
    },
    [dispatch]
  );

  const setLoading = useCallback(
    (name, value = true) => {
      dispatch({
        type: 'setLoading',
        payload: {
          name,
          value,
        },
      });
    },
    [dispatch]
  );

  const addGlobalSnackBar = useCallback(
    (globalSnackBar) => {
      dispatch({
        type: 'addGlobalSnackBar',
        payload: { globalSnackBar },
      });
    },
    [dispatch]
  );

  const removeGlobalSnackBar = useCallback(
    (id) => {
      dispatch({
        type: 'removeGlobalSnackBar',
        payload: { id },
      });
    },
    [dispatch]
  );

  const setSelectedContract = useCallback(
    (contract) => {
      dispatch({
        type: 'setSelectedContract',
        payload: {
          contract,
        },
      });
    },
    [dispatch]
  );

  const setSelectedRun = useCallback(
    (run) => {
      dispatch({
        type: 'setSelectedRun',
        payload: {
          run,
        },
      });
    },
    [dispatch]
  );

  const setCurrency = useCallback(
    (currency) => {
      dispatch({
        type: 'setCurrency',
        payload: {
          currency,
        },
      });
    },
    [dispatch]
  );

  const setTargetOverview = useCallback(
    (targetOverview) => {
      dispatch({
        type: 'setTargetOverview',
        payload: {
          lastPrice: targetOverview.lastPrice,
          priceChange: targetOverview.priceChange,
          changeRate: targetOverview.changeRate,
        },
      });
    },
    [dispatch]
  );
  const setMarketTargetOverview = useCallback(
    (targetOverview) => {
      dispatch({
        type: 'setMarketTargetOverview',
        payload: {
          lastPrice: targetOverview.lastPrice,
          priceChange: targetOverview.priceChange,
          changeRate: targetOverview.changeRate,
        },
      });
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'reset',
    });
  }, [dispatch]);

  const setIsTenantApp = useCallback(
    (isTenantApp) => {
      dispatch({
        type: 'setIsTenantApp',
        payload: isTenantApp,
      });
    },
    [dispatch]
  );

  return {
    setData,
    setMultiData,
    setLoading,
    setSelectedContract,
    setSelectedRun,
    setCurrency,
    setTargetOverview,
    setMarketTargetOverview,
    reset,
    setIsTenantApp,
    addGlobalSnackBar,
    removeGlobalSnackBar,
  };
}
