import colors from 'src/styles/colors';

function SvgChevron({
  active,
  color = colors.primary,
  left = false,
  ...otherProps
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 3 5.091"
      color={color}
      style={{
        transform: left ? 'scaleX(-1)' : '',
      }}
      opacity={active ? 1 : 0.2}
      {...otherProps}
    >
      <path
        d="M.133.133a.455.455 0 000 .642l1.446 1.449a.455.455 0 010 .642L.133 4.315a.455.455 0 000 .642.454.454 0 00.643 0l2.091-2.091a.454.454 0 000-.643L.776.132a.455.455 0 00-.643.001z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevron;
