import { makeStyles } from '@mui/styles';
import illustrationNoData from 'src/assets/images/explore-no-data.svg';
import clsx from 'clsx';
import Text from '../Text/Text';

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  img: {
    width: 140,
  },
}));

export default function NoDataInChart(props) {
  const { className, textOnly, textSize = '18' } = props;
  const classes = styles(props);

  return (
    <div className={clsx(classes.root, className)}>
      {!textOnly && (
        <>
          <img src={illustrationNoData} alt="no data" className={classes.img} />
          <br height={30} />
        </>
      )}
      <Text bold size={textSize} color="textSecondary">
        No data to display
      </Text>
    </div>
  );
}
