import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import colors from 'src/styles/colors';
import { fontWeights, getRem } from 'src/styles/fonts/font';
import Text from '../Text/Text';

const spring = {
  type: 'spring',
  stiffness: 400,
  damping: 30,
  // duration: 1.5,
  // bounce: 0.25,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: 15,
    color: colors.textSecondary,
    fontSize: getRem(16),
    fontWeight: fontWeights.SemiBold,
  },
  tabs: ({ inHeader }) => ({
    display: 'flex',
    alignItems: 'center',
    border: inHeader
      ? `1px solid ${colors.primary}`
      : `1px solid ${colors.selectTabsBorder}`,
    borderRadius: 8,
    backgroundColor: colors.light,
  }),
  tab: ({ inHeader }) => ({
    cursor: 'pointer',
    position: 'relative',
    padding: inHeader ? '11.5px 14px' : '7px 14px',
    zIndex: 1,
    '& p': {
      zIndex: 3,
      position: 'relative',
    },
  }),
  tabOutline: {
    zIndex: 2,
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    background: colors.secondary,
    borderRadius: 6,
  },
  tabLabel: {
    fontSize: getRem(14),
    fontWeight: fontWeights.SemiBold,
  },
  tabLabelActive: {
    color: colors.light,
  },
  tabLabelDisabled: {
    opacity: 0.3,
  },
}));

function Item({ label, isSelected, onClick, disabled, inHeader }) {
  const classes = useStyles({ inHeader });
  return (
    <div className={classes.tab} onClick={disabled ? undefined : onClick}>
      {isSelected && (
        <motion.div
          layoutId="outline"
          className={classes.tabOutline}
          initial={false}
          transition={spring}
        />
      )}

      <Text
        className={clsx([
          classes.tabLabel,
          { [classes.tabLabelDisabled]: disabled },
        ])}
      >
        <motion.span
          transition={spring}
          animate={{ color: isSelected ? '#fff' : '#000' }}
        >
          {label}
        </motion.span>
      </Text>
    </div>
  );
}

export default function SelectTabs({
  sectionName,
  tabs,
  value = null, // if controlled value
  initialTabIndex = null,
  onTabClicked,
  tabsTitle,
  DateRangeComponent,
  inHeader = false,
}) {
  const classes = useStyles({ inHeader });

  const isControlled = typeof value === 'number';

  const [activeIndex, setActiveIndex] = useState(initialTabIndex);
  const [prevInitialTabIndex, setPrevInitialTabIndex] =
    useState(initialTabIndex);

  const handleTabClick = useCallback(
    (i, item) => () => {
      if (!isControlled) {
        setActiveIndex(i);
      }
      if (typeof onTabClicked === 'function') {
        onTabClicked(sectionName, i, item);
      }
    },
    [isControlled, onTabClicked, sectionName]
  );

  // initialTabIndex effect
  useEffect(() => {
    if (prevInitialTabIndex !== initialTabIndex) {
      setActiveIndex(initialTabIndex);
      setPrevInitialTabIndex(initialTabIndex);
    }
  }, [initialTabIndex, prevInitialTabIndex]);

  return (
    <div className={classes.root}>
      {Boolean(tabsTitle) && <Text className={classes.title}>{tabsTitle}</Text>}
      <div className={classes.tabs}>
        <AnimateSharedLayout>
          {tabs.map((item, i) => {
            const active = isControlled ? value === i : activeIndex === i;
            return (
              <Item
                key={item.id || item.label}
                label={item.label}
                isSelected={active}
                onClick={handleTabClick(i, item)}
                disabled={
                  typeof item.enabled === 'boolean'
                    ? !item.enabled
                    : item.tempDisabled
                }
                inHeader={inHeader}
              />
            );
          })}
          {DateRangeComponent}
        </AnimateSharedLayout>
      </div>
    </div>
  );
}
