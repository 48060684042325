import { Button, Container, Grid, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StaticDatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Text from '../Text/Text';

const useStyles = makeStyles((theme) => ({
  root: {},
  datePickerContainer: {
    boxShadow: '5px 5px 12px #0000000A',
    border: '0.5px solid #A5AFBE66',
    '&:first-of-type': {
      marginRight: theme.spacing(2.5),
    },
  },
  popoverContent: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    borderRadius: 10,
    '&:first-of-type': {
      marginRight: theme.spacing(1.5),
    },
  },
  buttonCancel: {},
  buttonValidate: {},
  toolBar: {
    backgroundColor: theme.palette.primary.main,
    '& .MuiTypography-root': {
      color: 'white',
    },
  },
}));

export default function DateRange({
  onValidate,
  popoverTarget,
  setOpenPopover,
  setClosePopover,
  initialStartDate,
  initialEndDate,
  maxDate,
  disableFuture = true,
}) {
  const classes = useStyles();
  const formattedInitialStartDate = moment(initialStartDate);
  const formattedInitialEndDate = initialEndDate
    ? moment(initialEndDate)
    : moment();

  const [startDate, setStartDate] = useState(formattedInitialStartDate);
  const [prevInitialStartDate, setPrevInitialStartDate] = useState(
    formattedInitialStartDate
  );
  const [endDate, setEndDate] = useState(formattedInitialEndDate);
  const [prevInitialEndDate, setPrevInitialEndDate] = useState(
    formattedInitialEndDate
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popOverId = open ? 'simple-popover' : undefined;

  const openPopOver = useCallback(() => {
    setAnchorEl(document.getElementById(popoverTarget));
  }, [popoverTarget]);

  const closePopOver = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDateChange = useCallback(
    (type) => (date) => {
      if (type === 'start') {
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    },
    []
  );

  const validate = useCallback(() => {
    onValidate(moment(startDate).toDate(), moment(endDate).toDate());
    closePopOver();
  }, [onValidate, startDate, endDate, closePopOver]);

  const cancel = useCallback(() => {
    closePopOver();
  }, [closePopOver]);

  const maxDateError = startDate?.toISOString() > endDate?.toISOString();

  useEffect(() => {
    if (!formattedInitialStartDate.isSame(prevInitialStartDate)) {
      setStartDate(formattedInitialStartDate);
      setPrevInitialStartDate(formattedInitialStartDate);
    }
    if (!formattedInitialEndDate.isSame(prevInitialEndDate)) {
      setEndDate(formattedInitialEndDate);
      setPrevInitialEndDate(formattedInitialEndDate);
    }
  }, [
    formattedInitialEndDate,
    formattedInitialStartDate,
    prevInitialEndDate,
    prevInitialStartDate,
  ]);

  useEffect(() => {
    if (setOpenPopover) {
      setOpenPopover(() => () => openPopOver());
    }
    if (setClosePopover) {
      setClosePopover(() => closePopOver());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <Container className={classes.popoverContent}>
      <Grid container style={{ position: 'relative' }}>
        <div className={classes.datePickerContainer}>
          <StaticDatePicker
            open
            format="DD/MM/yyyy"
            views={['year', 'month', 'day']}
            value={startDate}
            maxDate={endDate}
            onChange={handleDateChange('start')}
            variant="static"
            id="date-picker-inline"
            disableFuture={disableFuture}
            inputVariant="outlined"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            slots={{
              actionBar: () => null,
            }}
            slotProps={{
              toolbar: {
                hidden: false,
                className: classes.toolBar,
              },
              rightArrowIcon: {
                color: 'secondary',
              },
              leftArrowIcon: {
                color: 'secondary',
              },
            }}
          />
        </div>
        <div className={classes.datePickerContainer}>
          <StaticDatePicker
            open
            format="DD/MM/yyyy"
            views={['year', 'month', 'day']}
            value={endDate}
            minDate={startDate}
            maxDate={maxDate}
            onChange={handleDateChange('end')}
            id="date-picker-inline"
            disableFuture={disableFuture}
            inputVariant="outlined"
            slots={{
              actionBar: () => null,
            }}
            slotProps={{
              toolbar: {
                hidden: false,
                className: classes.toolBar,
              },
              rightArrowIcon: {
                color: 'secondary',
              },
              leftArrowIcon: {
                color: 'secondary',
              },
            }}
          />
        </div>
      </Grid>
      {maxDateError && (
        <div className={classes.buttonsContainer}>
          <Text color="error" bold>
            End date must be after start date
          </Text>
        </div>
      )}
      <div className={classes.buttonsContainer}>
        <Button
          className={clsx(classes.button, classes.buttonCancel)}
          onClick={cancel}
          variant="outlined"
          color="primary"
        >
          <Text size={14} weight="SemiBold">
            Cancel
          </Text>
        </Button>
        <Button
          className={clsx(classes.button, classes.buttonValidate)}
          onClick={maxDateError ? undefined : validate}
          variant="outlined"
          color="secondary"
          style={{ opacity: maxDateError ? 0.6 : 1 }}
        >
          <Text size={14} weight="SemiBold" color="secondary">
            Apply
          </Text>
        </Button>
      </div>
    </Container>
  );

  return (
    <Popover
      id={popOverId}
      open={open}
      anchorEl={anchorEl}
      onClose={closePopOver}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.popOver}
    >
      {content}
    </Popover>
  );
}
