import { createContext, useEffect, useContext, useReducer } from 'react';
import { initialState, dataReducer as reducer } from './reducer';
import {
  getFromSStoreOrDefault,
  STORAGE_KEY_PREFIX,
} from '../persistence/storage';

const DataStateContext = createContext(initialState);

const DataDispatchContext = createContext();

export const DATA_STORAGE_KEY = `${STORAGE_KEY_PREFIX}DATA`;

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    getFromSStoreOrDefault(initialState, DATA_STORAGE_KEY).then((defState) => {
      if (JSON.stringify(defState) !== JSON.stringify(initialState)) {
        dispatch({ type: 'init', payload: defState });
      }
    });
  }, []);

  return (
    <DataStateContext.Provider value={state}>
      <DataDispatchContext.Provider value={dispatch}>
        {children}
      </DataDispatchContext.Provider>
    </DataStateContext.Provider>
  );
};

export const useDataState = () => {
  const context = useContext(DataStateContext);
  if (context === undefined)
    throw new Error('useDataState must be used within a DataProvider');
  return context;
};

export const useDataDispatch = () => {
  const context = useContext(DataDispatchContext);
  if (context === undefined)
    throw new Error('useDataDispatch must be used within a DataProvider');
  return context;
};
