import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import HeaderNoAccountPopover from '../common/header-no-account-popover';

// ----------------------------------------------------------------------

export default function UnauthenticatedLayout({ children }) {
  return (
    <>
      <HeaderNoAccountPopover />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}

UnauthenticatedLayout.propTypes = {
  children: PropTypes.node,
};
