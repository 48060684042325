import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'src/services/auth/useAuth';
import { AuthStateContext } from 'src/services/auth/context';

export const PrivateRoute = () => {
  const location = useLocation();
  const endRoute = location.pathname.split('/').pop();
  const { scopes } = useContext(AuthStateContext);
  const { logout } = useAuth();
  // create a map for each route with a corresponding token scope/true
  const routeScopeMap = new Map([
    ['market', 'market-trends::read'],
    ['reporting', 'situation::read'],
    ['decision', 'recommendations::read'],
  ]);

  if (!scopes) {
    logout();
    return <Navigate to="/auth/jwt/login" replace state={{ from: location }} />;
  } else if (
    Array.from(routeScopeMap.keys()).includes(endRoute) &&
    scopes &&
    !scopes.includes(routeScopeMap.get(endRoute))
  ) {
    return <Navigate to="/" replace state={{ from: location }} />;
  } else {
    return <Outlet />;
  }
};
