import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo, useState } from 'react';
import colors from 'src/styles/colors';
import CollapseSection from './CollpaseSection';
import ModalChartSelector from './ModalChartSelector';

export const dataSideBarWidth = 338;
export const dataSideBarHorizontalPadding = 35;

const useStyles = makeStyles((theme) => ({
  root: ({ open }) => ({
    background: theme.palette.common.headerBgColor,
    padding: 0,
    width: dataSideBarWidth,
    // Animation
    position: 'relative',
    transform: `translateX(${open ? 0 : '-100%'})`,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    marginRight: 0,
    overflow: 'auto',
    height: '100vh',
  }),
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.primaryLightOp,
  },
}));

const DataSideBar = ({
  open,
  timeSeriesByPriceDriver,
  toggleElement,
  selectedItems,
}) => {
  const classes = useStyles({ open });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => {
      if (prevIsModalOpen) {
        setSelectedItemId(null);
      }

      return !prevIsModalOpen;
    });
  }, []);

  const onModalValidate = useCallback(
    (chartType) => {
      toggleElement({
        id: selectedItemId,
        chartType,
      });
      toggleModal();
    },
    [selectedItemId, toggleModal, toggleElement]
  );

  const onItemClicked = useCallback(
    (id) => {
      toggleElement({
        id: id,
        chartType: 'line',
      });
    },
    [toggleElement]
  );

  const data = useMemo(() => {
    return Object.values(timeSeriesByPriceDriver)?.map(
      ({ priceDriverName, timeSeries }) => {
        return {
          title: priceDriverName,
          sections: [
            {
              items: timeSeries,
            },
          ],
        };
      }
    );
  }, [timeSeriesByPriceDriver]);

  return (
    <Container className={classes.root} maxWidth={false}>
      {data?.map(({ title, sections }) => {
        return (
          <CollapseSection
            key={title}
            title={title}
            sections={sections}
            onItemClicked={onItemClicked}
            divider={<div className={classes.divider} />}
            selectedItems={selectedItems}
          />
        );
      })}
      {isModalOpen && (
        <ModalChartSelector
          open={isModalOpen}
          toggleModal={toggleModal}
          onValidate={onModalValidate}
        />
      )}
    </Container>
  );
};

export default DataSideBar;
