import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useAuth } from 'src/services/auth/useAuth';
import { useData } from 'src/services/data/useData';
import { useTenantManagement } from 'src/services/auth/useTenantManagement';

import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Change Account',
    linkTo: paths.dashboard.accounts,
    icon: <AutorenewIcon />,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user } = useData();

  const { logout, username } = useAuth();

  const popover = usePopover();

  const { clientList, fetchInitialClientList } = useTenantManagement();

  useEffect(() => {
    fetchInitialClientList();
  }, [fetchInitialClientList]);

  let isUniqueTenant = false;
  if (clientList?.length) isUniqueTenant = clientList.length === 1;

  const handleLogout = async () => {
    try {
      logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.photoURL}
          alt={user?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {username.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {username}
          </Typography>
          {/* 
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography> */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              disabled={
                option.label === 'Change Account' ? isUniqueTenant : false
              }
              onClick={() => handleClickItem(option.linkTo)}
            >
              <ListItemIcon sx={{ m: 0 }}>{option.icon}</ListItemIcon>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold' }}
        >
          <ListItemIcon sx={{ m: 0 }}>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
