import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const primary = {
  lighter: '#FEEDEF',
  light: '#EDA5A0',
  main: '#EC6C5B',
  dark: '#E25A42',
  darker: '#C24B36',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

export const warning = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const chartCategorical = {
  azuraRadiance: '#147AF3',
  java: '#0FB5AE',
  ectasy: '#F68511',
  cerise: '#DE3D82',
  governorBay: '#4046CA',
  pastelGreen: '#72E06A',
  cornflowerBlue: '#7E84FA',
  seaGreen: '#2f8255',
  tenn: '#CB5D00',
  corn: '#E8C600',
  pear: '#bae641',
};

export const sequential = {
  deepViolet: '#49006A',
  blueViolet: '#7A0177',
  eminence: '#AE017E',
  maximumPurple: '#DD3497',
  darkLavender: '#F768A1',
  frenchLilacSolid: '#FA9FB5',
  frenchLilac: '#FCC5C0',
  RegalBlue: '#004969',
  Orient: '#005f82',
  Allports: '#00739b',
  BondiBlue: '#0087b5',
  PacificBlue: '#009bce',
  Cerulean: '#00aff0',
  RobinsEggBlue: '#00c3ee',
  Nandor: '#526760',
  ViridianGreen: '#6c8579',
  Envy: '#86a492',
  SpringRain: '#a1c2ab',
  Ziggurat: '#bbddde',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode) {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
