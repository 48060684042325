import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { fontWeights, getRem } from 'src/styles/fonts/font';

/**
 * Variants
 *  h1
 *  h2
 *  h3
 *  h4
 *  h5
 *  h6
 *  subtitle1
 *  subtitle2
 *  body1
 *  body2
 *  button
 *  caption
 *  overline
 */
export const getFontWeight = (weight, bold = false) => {
  if (bold) {
    return fontWeights.Bold;
  }
  if (weight) {
    const _weight = fontWeights[weight];
    if (!_weight) {
      console.warn(`getFontWeight(): ${weight} not found in fontWeights`);
    }
    return _weight;
  }
  return undefined;
};

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    fontWeight: getFontWeight(props.weight, props.bold),
    color: theme.palette.common[props.color],
    ...(props.size
      ? {
          fontSize: getRem(props.size),
        }
      : {}),
  }),
}));

export default function Text(props) {
  const { children, className, style, variant } = props;
  const classes = useStyles(props);

  return (
    <Typography
      className={clsx(classes.root, className)}
      variant={variant}
      style={style}
    >
      {children}
    </Typography>
  );
}
