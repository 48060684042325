const light = 'white';
const primary = '#39556D';
const primaryLight = '#A5AFBE';
const primaryLight2 = '#F6F8Fb';
const secondary = '#f79581';

module.exports = {
  // Main
  light,
  primary,
  primaryLight,
  primaryLightOp: '#A5AFBE33',
  primaryLight2,
  secondary,
  orange: '#E85E4C',
  primaryDark: '#445b6f',
  secondaryDark: '#f47157',
  secondaryLight: '#ffa38d',

  // Palette from XD
  blue: primary,
  blueLight: '#39728D',
  blueLight2: '#1E9FDD',
  blueLight3: '#79D3FF',
  red: '#DD2506',
  redLight: '#EE4C30',
  redLight2: '#F79581',
  redLight3: '#FFCEC4',
  green: '#9CB122',
  greenLight: '#BAD02C',
  greenLight2: '#E3EDA7',
  greenLight3: '#F4F7E2',
  // Status
  info: '#edc747',
  success: '#22be96',
  error: '#EE4C30',
  // Texts
  textPrimary: primary,
  textSecondary: primaryLight,
  statLabel: '#363E4A',
  // Elements
  headerBgColor: light,
  screenBgColor: '#FAFAFA',
  drawerOverlayBg: '#A5AFBE80',
  sideBarBgColor: primary,
  drawerItemActive: secondary,
  drawerItemUnctive: primaryLight,
  drawerItemBgActive: '#4c657c',
  selectTabsBorder: '#A5AFBE66',
  exploreDataSideBardBottomlBg: '#EBEDF0',
  // Darks, greys, etc.
  greyLighter: '#fefefe', // TODO remove?
  greyLight: '#dedede', // TODO remove?
  grey: '#cecece', // TODO remove?
  dark: '#222', // TODO remove?
  //Scenarios graphs palette
  TiffanyBlue: '#10b5ae',
  oceanBlue: '#4046ca',
  Beer: '#F68512',
  deepCerise: '#DE3C82',
  mediumSlateBlue: '#7E84F9',
  pastelGreen: '#71E06A',
  CrayolaBlue: '#157AF3',
  blueViolet: '#7326D3',
  munsellYellow: '#E7C600',
  tawnyOrange: '#CA5C02',
  spanishGreen: '#008F5D',
  pear: '#BCE930',
  dodgerBlue: '#1E90FF',
  firebrick: '#B22222',
  darkOrange: '#FF8C00',
  teal: '#008080',
  lavender: '#E6E6FA',
  sienna: '#A0522D',
  mediumPurple: '#9370DB',
  chartreuse: '#7FFF00',
  royalBlue: '#4169E1',
  indigo: '#4B0082',
  darkOliveGreen: '#556B2F',
  rosyBrown: '#BC8F8F',
  deepSkyBlue: '#00BFFF',
  crimson: '#DC143C',
  springGreen: '#00FF7F',
  darkMagenta: '#8B008B',
  yellowGreen: '#9ACD32',
  mediumTurquoise: '#48D1CC',
  orangeRed: '#FF4500',
  darkViolet: '#9400D3',
  oliveDrab: '#6B8E23',
  sandyBrown: '#F4A460',
  mediumSeaGreen: '#3CB371',
  purple: '#800080',
  limeGreen: '#32CD32',
  darkTurquoise: '#00CED1',
  chocolate: '#D2691E',
  forestGreen: '#228B22',
  goldenrod: '#DAA520',
  darkSlateGray: '#2F4F4F',
  cornflowerBlue: '#6495ED',
  saddleBrown: '#8B4513',
  skyBlue: '#87CEEB',
  steelBlue: '#4682B4',
  tan: '#D2B48C',
  thistle: '#D8BFD8',
  tomato: '#FF6347',
  fuchsia: '#FF00FF',
  lime: '#00FF00',
  darkGoldenrod: '#B8860B',
  blueSapphire: '#0F52BA',
  goldenYellow: '#FFDF00',
  hotPink: '#FF69B4',
  lightSeaGreen: '#20B2AA',
  redOrange: '#FF5349',
  navy: '#000080',
  paleGreen: '#98FB98',
  babyPink: '#F9C2CB',
  sandyBeach: '#FDD9B5',
  slateBlue: '#6A5ACD',
  amethyst: '#9966CC',
  wheat: '#F5DEB3',
  lemonYellow: '#FFF44F',
  azure: '#007FFF',
  brickRed: '#CB4154',
  deepPink: '#FF1493',
  oliveGreen: '#B5B35C',
  midnightBlue: '#191970',
  pumpkin: '#FF7518',
  darkLavender: '#734F96',
  lightTurquoise: '#9AD5D5',
  darkCyan: '#008B8B',
  coral: '#FF7F50',

  //Consumption colors

  requiredPurple: '#FF40FF',
  flatFesh: '#fad390',
  melonMelody: '#f8c291',
  livid: '#6a89cc',
  spray: '#82ccdd',
  paradiseGreen: '#b8e994',
  squashBlossom: '#f6b93b',
  mandarinRed: '#e55039',
  azraqBlue: '#4a69bd',
  dupain: '#60a3bc',
  auroraGreen: '#78e08f',
  icelandPoppy: '#fa983a',
  tomatoRed: '#eb2f06',
  yueGuangLanBlue: '#1e3799',
  goodSamaritan: '#3c6382',
  waterFall: '#38ada9',
  carrotOrange: '#e58e26',
  jalapenoRed: '#b71540',
  darkSaphire: '#0c2461',
  forestBlues: '#0a3d62',
  reefEncounter: '#079992',
};
