import { makeStyles } from '@mui/styles';
import SvgChevron from 'src/assets/images/SvgChevron';
import Collapse from 'src/components/Collapse/Collapse';
import Text from 'src/components/Text/Text';
import { useState } from 'react';
import colors from 'src/styles/colors';
import { fontWeights, getRem } from 'src/styles/fonts/font';
import { dataSideBarHorizontalPadding } from './DataSideBar';
import SubSection from './SubSection';

const useStyles = makeStyles(() => ({
  root: {},
  top: {
    padding: `21px ${dataSideBarHorizontalPadding}px`,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: getRem(18),
    fontWeight: fontWeights.Bold,
    color: colors.secondary,
  },
  content: {
    padding: `20px ${dataSideBarHorizontalPadding}px 12px ${dataSideBarHorizontalPadding}px`,
    width: '100%',
  },
}));

export default function CollapseSection({
  title,
  sections,
  onItemClicked,
  divider,
  selectedItems,
}) {
  const classes = useStyles();
  const [collapsed, onCollapse] = useState(false);

  return (
    <div className={classes.root}>
      <Collapse
        initialCollapse={collapsed}
        onCollapse={onCollapse}
        contentClassName={classes.content}
        topClassName={classes.top}
        top={
          <>
            <Text className={classes.title}>{`— ${title}`}</Text>
            <SvgChevron
              color={colors.secondary}
              opacity={1}
              transform={`rotate(${collapsed ? '90' : '-90'})`}
            />
          </>
        }
        divider={divider}
      >
        {sections?.map(({ title, items }, i) => {
          return (
            <SubSection
              key={i}
              title={title}
              items={items}
              onItemClicked={onItemClicked}
              selectedItems={selectedItems}
            />
          );
        })}
      </Collapse>
    </div>
  );
}
