import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import illustrationNoData from 'src/assets/images/explore-no-data.svg';
import Br from 'src/components/Br/Br';
import DateRange from 'src/components/DateRange/DateRange';
import { contentMaxWidth, screenPaddings } from 'src/components/Screen/Screen';
import SelectTabs from 'src/components/SelectTabs/SelectTabs';
import Text from 'src/components/Text/Text';
import ExploreFullChartCommodity from 'src/components/dataviz/ChartCommodity/ExploreFullChartCommodity';
import ExploreFullChartProbabilityOfVariation from 'src/components/dataviz/ChartProbabilityOfVariation/ExploreFullChartProbabilityOfVariation';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useData } from 'src/services/data/useData';
import colors from 'src/styles/colors';
import { getStartFromEndWithPeriod } from 'src/utils/utils';
import { dataSideBarWidth } from '../DataSideBar/DataSideBar';

export const periods = [
  {
    id: '7D',
    label: '7D',
  },
  {
    id: '30D',
    label: '30D',
  },
  {
    id: '90D',
    label: '90D',
  },
  {
    id: '1Y',
    label: '1Y',
  },
  {
    id: '5Y',
    label: '5Y',
  },
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'dateRange',
    label: 'Date range',
    isDateRange: true,
  },
];

const initialTabIndex = periods.indexOf(periods.find((p) => p.id === 'all'));
const defaultPeriod = periods[initialTabIndex];

const _initialStartDate = moment(new Date()).subtract(1, 'months'); // last month
const _initialEndDate = new Date();

const useStyles = makeStyles((theme) => ({
  root: ({ isSideBardOpened }) => ({
    overflow: 'auto',
    height: '100vh',
    flex: 1,
    backgroundColor: colors.screenBgColor,
    // Animation
    position: 'relative',
    marginLeft: isSideBardOpened ? 0 : -dataSideBarWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  contentContainer: {
    flex: 1,
    maxWidth: contentMaxWidth,
    paddingTop: '37px',
    paddingBottom: screenPaddings(theme).bottom,
    paddingLeft: screenPaddings(theme).left,
    paddingRight: screenPaddings(theme).right,
  },
  contentContainerNoData: {
    flex: 1,
    display: 'flex',
  },
  noDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateRangeContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  dateRangeButton: {
    margin: 0,
  },
}));

const Content = ({
  isSideBardOpened,
  selectedTimeSeries,
  getTimeSerieData,
  selectedItems,
  period,
  periodIndex,
  onTabPeriodChange,
  openDetailsPanel,
}) => {
  const classes = useStyles({ isSideBardOpened });
  const hasData = !!selectedTimeSeries?.length;

  const [openPopover, setOpenPopover] = useState();
  const [{ initialStartDate, initialEndDate }, setInitialDateRange] = useState({
    initialStartDate: _initialStartDate,
    initialEndDate: _initialEndDate,
  });

  const isDefaultStartDate = useMemo(() => {
    return (
      moment(initialStartDate).format('YYY-MM-DD') ===
      moment(_initialStartDate).format('YYY-MM-DD')
    );
  }, [initialStartDate]);

  const { selectedRun } = useData();

  const onRunChange = useCallback(
    (run) => {
      // const period = selectedPeriod || defaultPeriod;
      const end = new Date(run.production_date);
      const start = period.isDateRange
        ? initialStartDate
        : getStartFromEndWithPeriod(period.id, end);

      setInitialDateRange({
        initialStartDate: start,
        initialEndDate: end,
      });
    },
    [period, initialStartDate]
  );

  /**
   * DateRange
   */

  const onValidateDateRange = useCallback(
    (startDate, endDate) => {
      setInitialDateRange({
        initialStartDate: startDate,
        initialEndDate: endDate,
      });

      const period = periods.find((p) => p.isDateRange);
      const periodIndex = periods.findIndex((p) => p.isDateRange);
      onTabPeriodChange(period, periodIndex);
      // fetchTimeSerie(selectedPeriod, { startDate, endDate });
    },
    [onTabPeriodChange]
  );

  /**
   * Periods
   */

  const [prevStartDate, setPrevLastDate] = useState(null);
  const [prevPeriod, setPrevPeriod] = useState(null);

  const onPeriodClicked = useCallback(
    (sectionName, periodIndex, item) => {
      const period = periods[periodIndex];
      const wasDateRangeBefore = prevPeriod?.id === 'dateRange';

      let start = prevStartDate;
      const end = wasDateRangeBefore
        ? initialEndDate
        : new Date(selectedRun?.production_date);

      if (item.isDateRange) {
        if (!start) {
          start = moment(end).subtract(7, 'days');
        } else {
          start = wasDateRangeBefore
            ? prevStartDate
            : getStartFromEndWithPeriod(prevPeriod.id, end);
        }

        openPopover();
      } else {
        start = getStartFromEndWithPeriod(period.id, end);
      }

      setPrevPeriod(period);

      setPrevLastDate(start);
      setInitialDateRange({
        initialStartDate: start,
        initialEndDate: end,
      });
      // setSelectedPeriod(period);

      if (!period.isDateRange) {
        onTabPeriodChange(item, periodIndex);
      }
    },
    [
      prevPeriod,
      prevStartDate,
      initialEndDate,
      selectedRun?.production_date,
      openPopover,
      onTabPeriodChange,
    ]
  );

  /**
   * Effect
   */

  useEffect(() => {
    if (selectedRun && !initialStartDate) {
      onRunChange(selectedRun);
    }
  }, [onRunChange, selectedRun, initialStartDate]);

  // useEffect(() => {
  //   if (!selectedPeriod && selectedRun) {
  //     // setSelectedPeriod(defaultPeriod);
  //     setPrevPeriod(defaultPeriod.id);
  //   }
  // }, [selectedPeriod, selectedRun]);

  // useEffect(() => {
  //   if (!selectedPeriod && selectedRun) {
  //     setSelectedPeriod(defaultPeriod);
  //     setPrevPeriod(defaultPeriod.id);
  //   }
  // }, [periodIndex]);

  if (!hasData) {
    return (
      <Container maxWidth="lg" className={classes.contentContainerNoData}>
        <div className={classes.noDataContainer}>
          <img src={illustrationNoData} alt="no data" />
          <Br height={30} />
          <Text bold size="24" color="primaryLight">
            Select data to display
          </Text>
        </div>
      </Container>
    );
  }

  // Needed for the Bar Chart (for now at least)
  const _wasDateRangeBefore = prevPeriod?.id === 'dateRange';
  const endDate = _wasDateRangeBefore
    ? initialEndDate
    : new Date(selectedRun?.production_date);

  return (
    <Container maxWidth={false} className={classes.root}>
      <Container maxWidth={false} className={classes.contentContainer}>
        <Container
          className={classes.dateRangeContainer}
          disableGutters
          id="target-daterange-popover"
        >
          <SelectTabs
            initialTabIndex={initialTabIndex}
            value={periodIndex}
            sectionName=""
            tabs={periods}
            tabsTitle=""
            onTabClicked={onPeriodClicked}
            DateRangeComponent={
              <DateRange
                className={classes.dateRangeButton}
                onValidate={onValidateDateRange}
                popoverTarget="target-daterange-popover"
                setOpenPopover={setOpenPopover}
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
              />
            }
          />
        </Container>
        <Br height={37} />
        {selectedTimeSeries.map((timeSerie) => {
          return (
            <Grid key={timeSerie.id} container spacing={3} pb={1.5}>
              <Grid item xs={12} md={12} lg={12}>
                {selectedItems?.find((i) => i.id === timeSerie.id)
                  ?.chartType === 'line' ? (
                  <ExploreFullChartCommodity
                    timeSerie={timeSerie}
                    selectedRun={selectedRun}
                    defaultPeriod={defaultPeriod}
                    selectedPeriod={period}
                    prevPeriod={prevPeriod}
                    isDefaultStartDate={isDefaultStartDate}
                    initialStartDate={initialStartDate}
                    initialEndDate={initialEndDate}
                    buttonSeeMore
                    openDetailsPanel={openDetailsPanel}
                  />
                ) : (
                  <ExploreFullChartProbabilityOfVariation
                    timeSerie={timeSerie}
                    getTimeSerieData={getTimeSerieData}
                    isDefaultStartDate={isDefaultStartDate}
                    initialStartDate={initialStartDate}
                    endDate={endDate}
                    periodId={period.id}
                  />
                )}
              </Grid>
            </Grid>
          );
        })}
      </Container>
    </Container>
  );
};

export default Content;
