import { Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

export const useCardStyles = makeStyles((theme) => ({
  root: () => ({
    backgroundColor: theme.palette.common.cardBgColor,
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 12,
  }),
  content: () => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.cardContentBgColor,
  }),
  contentCenter: ({ left, top, bottom }) => ({
    flex: 1,
    ...(top & bottom ? {} : {}),
    ...(left
      ? {
          borderRadius: '3px 3px 10px 3px',
        }
      : {}),
  }),
  contentTop: () => ({
    marginBottom: theme.spacing(2),
    borderRadius: '10px 10px 3px 3px',
  }),
  contentBottom: () => ({
    marginTop: theme.spacing(2),
    borderRadius: '3px 3px 10px 10px',
  }),
  contentLeft: () => ({
    marginRight: theme.spacing(2),
    borderRadius: '3px 3px 3px 10px',
    display: 'flex',
    maxHeight: '350px',
    paddingRight: 0,
  }),
  contentLeftHack: () => ({
    // HACK: This custom handling solves a nasty bug: "If no "left" element is provided, the children (chart) will losse his height"
    marginRight: theme.spacing(-4),
    backgroundColor: 'transparent',
  }),
  maxWidthParent: {
    maxWidth: '100%', // important, without that, the chart will infinitely animate on small screens
  },
}));
export const BottomPart = (props) => {
  const classes = useCardStyles(props);

  return (
    <div
      className={clsx(
        classes.content,
        classes.contentBottom,
        props.contentClassName
      )}
      style={{ ...props.style }}
    >
      {props.content}
    </div>
  );
};
export default function Card(props) {
  const {
    children,
    manualContent = false,
    className,
    contentClassName,
    childrenClassName,
    topClassName,
    left,
    top,
    bottom,
  } = props;
  const classes = useCardStyles(props);

  if (manualContent) {
    return <Paper className={clsx(classes.root, className)}>{children}</Paper>;
  }

  return (
    <Paper className={clsx(classes.root, className)}>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        className={classes.maxWidthParent}
      >
        {Boolean(top) && (
          <div
            className={clsx(
              classes.content,
              classes.contentTop,
              contentClassName,
              topClassName
            )}
          >
            {top}
          </div>
        )}
        <Box display="flex" minHeight="1px">
          {/* HACK: This custom handling solves a nasty bug: "If no "left" element is provided, the children (chart) will losse his height" */}
          {left ? (
            <div
              className={clsx(
                classes.content,
                classes.contentLeft,
                contentClassName
              )}
            >
              {left}
            </div>
          ) : (
            <div className={clsx(classes.content, classes.contentLeftHack)}>
              <p />
            </div>
          )}
          <div
            className={clsx(
              classes.content,
              classes.contentCenter,
              contentClassName,
              childrenClassName
            )}
          >
            {children}
          </div>
        </Box>
        {Boolean(bottom) && (
          <BottomPart
            props={props}
            content={bottom}
            contentClassName={contentClassName}
          />
        )}
      </Box>
    </Paper>
  );
}
