const STORAGE_PROVIDER = window.localStorage;

export const STORAGE_KEY_PREFIX = 'DATAPRED_0.0.3_';

export const getFromSStoreOrDefault = async (defaultState, key) => {
  const value = STORAGE_PROVIDER.getItem(key);
  return (value && JSON.parse(value)) || defaultState;
};
export const saveToSStore = async (state, key) => {
  const value = STORAGE_PROVIDER.setItem(key, JSON.stringify(state));
  return value || state;
};
