import { useCallback, useEffect, useMemo } from 'react';
import jwt_decode from 'jwt-decode';

import useAuthActions from './actions';
import useAuthQueries from './queries';
import { useAuthState, useAuthDispatch } from './context';
import axios from 'axios';
import { isDev, sleep } from 'src/utils/utils';
import useDataActions from '../data/actions';
import { useDataDispatch } from '../data/context';
import { version } from '../../_git_version.js';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useLocation } from 'react-router';

//const frontend_version = GitCommit.logMessage
const frontend_version = version;
console.log('Frontend Version:', version);
if (frontend_version) {
  axios.defaults.headers.common['Accept'] =
    'application/json; version=' + frontend_version;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const msg_reload =
      'The server has changed version since you opened this ' +
      'page, you now need to reload this page.';
    if (error.response.status === 406 && window.confirm(msg_reload)) {
      window.location.reload();
      return;
    }
    throw error;
  }
);

// axios.interceptors.request.use(
//   (config) => {
//     // Perform a task before the request is sent
//     const { authorization } = config.headers || {};

//     if (authorization) {
//       const accessToken = authorization.split('Bearer ')[1];
//       if (isTokenExpired(accessToken)) {
//         // TODO REFRESH LOGIC here
//         // Then, cancel the original request
//         // throw new axios.Cancel('accessToken is expired or null');
//       }
//     }

//     return config;
//   },
//   (error) => {
//     // handle the error
//     return Promise.reject(error);
//   }
// );

export const useAuth = () => {
  const { rehydrated, accessToken, refreshToken, username } = useAuthState();
  const dispatch = useAuthDispatch();
  const dataDispatch = useDataDispatch();
  const { loginSucceeded, logout: logoutAction } = useAuthActions(dispatch);
  const { reset: resetData } = useDataActions(dataDispatch);
  const {
    register: registerRequest,
    sendResetPasswordLink: sendResetPasswordLinkRequest,
    resetPassword: passwordResetRequest,
    login: loginRequest,
  } = useAuthQueries();

  const router = useRouter();
  const location = useLocation();

  const login = useCallback(
    ({ username: _username, password }) => {
      return loginRequest({ username: _username, password })
        .then((res) => {
          if (res?.data?.access) {
            loginSucceeded({
              access: res.data.access,
              refresh: res.data.refresh,
              username: _username,
              scopes: jwt_decode(res.data.access).scope
                ? jwt_decode(res.data.access).scope.split(' ')
                : [],
            });
          } else {
            alert('An error occured');
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response?.status === 401) {
            return (
              Promise.reject(e.response && e.response.data) ||
              'Something went wrong'
            );
          } else {
            return Promise.reject('Something went wrong');
          }
        });
    },
    [loginRequest, loginSucceeded]
  );

  const register = useCallback(
    ({
      company,
      first_name,
      last_name,
      email,
      username: _username,
      password,
    }) => {
      return registerRequest({
        company,
        first_name,
        last_name,
        email,
        username: _username,
        password,
      })
        .then((res) => {
          if (res?.status === 201) {
            // Auto login
            loginRequest({
              username: _username,
              password,
            }).then((res) => {
              loginSucceeded({
                access: res.data.access,
                refresh: res.data.refresh,
                username: _username,
              });
            });
          } else {
            throw new Error('An error occured');
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response?.status === 401) {
            alert('Unauthorized, please check your credentials.');
          } else {
            if (e.response && e.response.data) {
              for (const error_message of e.response.data.errors) {
                alert(error_message);
              }
            } else {
              alert('An error occured');
            }
          }
          throw e;
        });
    },
    [registerRequest, loginSucceeded, loginRequest]
  );

  const sendResetPasswordLink = useCallback(
    ({ login }) => {
      return sendResetPasswordLinkRequest({ login })
        .then(() => {
          console.log(
            'You will receive an email shortly. Click on the link it contains to reset your password.'
          );
        })
        .catch((e) => {
          if (e.response.status === 500) {
            return Promise.reject('Something went wrong');
          } else {
            return (
              Promise.reject(e.response && e.response.data) ||
              'Something went wrong'
            );
          }
        });
    },
    [sendResetPasswordLinkRequest]
  );

  const resetPassword = useCallback(
    ({ user_id, timestamp, signature, password }) => {
      return passwordResetRequest({
        user_id,
        timestamp,
        signature,
        password,
      })
        .then(() => {
          router.push(paths.auth.jwt.login);
        })
        .catch((e) => {
          if (e.response?.status === 400 && e.response.data?.password) {
            return (
              Promise.reject(
                JSON.stringify(e.response.data.password, null, 2)
              ) || 'Something went wrong'
            );
          } else {
            return (
              Promise.reject(e.response && e.response.data) ||
              'Something went wrong'
            );
          }
        });
    },
    [passwordResetRequest, router]
  );

  const logout = useCallback(() => {
    logoutAction();
    localStorage.setItem('logged', false);
    sleep(100).then(() => {
      if (
        location.pathname.split('/').includes('forgot-password') ||
        location.pathname.split('/').includes('verify')
      ) {
        router.push(location.pathname);
      } else {
        router.push(paths.auth.jwt.login);
      }
      resetData();
    });
  }, [logoutAction, resetData, router, location.pathname]);

  const authenticatedHeaders = useMemo(
    () =>
      accessToken
        ? {
            authorization: `Bearer ${accessToken}`,
          }
        : {},
    [accessToken]
  );

  useEffect(() => {}, []);

  const authenticatedRequest = useCallback(
    (method, url, options = {}, data) => {
      if (!options?.headers?.authorization) {
        options.headers = {
          ...(options.headers || {}),
          ...authenticatedHeaders,
        };
      }

      let promise;
      if (method === 'get') {
        promise = axios.get(url, options);
      } else if (method === 'post') {
        promise = axios.post(url, data, options);
      } else if (method === 'put') {
        promise = axios.put(url, data, options);
      } else if (method === 'patch') {
        promise = axios.patch(url, data, options);
      } else if (method === 'delete') {
        promise = axios.delete(url, options);
      } else {
        return Promise.reject({
          message: `Method ${method} not handled yet`,
        });
      }

      return promise.catch((error) => {
        if (isDev) {
          console.log(method, url, error);
        }

        if (error.response.status === 401 || error.response.status === 403) {
          // TODO REFRESH LOGIC here ?
          logout();
        }

        // Important: don't forget to always re-throw the error,
        // otherwise callers of authenticatedRequest() will get
        // a fullfilled promise back, as if the error had been dealt
        // with and replaced with a successful value.
        throw error;
      });
    },
    [logout, authenticatedHeaders]
  );

  return {
    // State
    authenticated: Boolean(accessToken),
    rehydrated,
    accessToken,
    refreshToken,
    authenticatedHeaders,
    username,
    // Actions
    register,
    sendResetPasswordLink,
    resetPassword,
    login,
    logout,
    authenticatedRequest,
  };
};
