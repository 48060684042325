import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Br from 'src/components/Br/Br';
import Text from 'src/components/Text/Text';
import { useCallback } from 'react';
import colors from 'src/styles/colors';

const buttonMargin = 8;

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  titleContainer: {
    width: '100%',
    padding: `11px 0`,
    textAlign: 'center',
    backgroundColor: colors.primaryLight2,
    marginBottom: 15,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    width: `calc(50% - ${buttonMargin / 2}px)`,
    minWidth: 'unset',
    '&:nth-child(even)': {
      marginLeft: buttonMargin,
    },
    marginBottom: buttonMargin,
    padding: '5px 10px',
    borderRadius: 8,
    justifyContent: 'space-between',
    border: `1px solid #edeff2`,
    '> span': {
      display: 'flex',
    },
  },
  buttonActive: {
    backgroundColor: colors.secondary,
  },
  buttonUnused: {
    backgroundColor: '#ccc !important',
  },
  buttonLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonLabelActive: {
    color: colors.light,
  },
  buttonIcon: {
    color: colors.primary,
    fontSize: '15px !important',
  },
  buttonIconActive: {
    color: colors.light,
  },
}));

export default function SubSection({ items, onItemClicked, selectedItems }) {
  const classes = useStyles();

  const handleItemClick = useCallback(
    (id) => () => {
      onItemClicked(id);
    },
    [onItemClicked]
  );

  items.sort((left, right) =>
    left.name > right.name ? 1 : left.name < right.name ? -1 : 0
  );

  return (
    <div className={classes.root}>
      {/**
       * The div below was planned in the UI prototype, but for now we only have a simple dimension of timeseries
       */}
      {/* <div className={classes.titleContainer}>
        <Text bold size={14}>
          {title}
        </Text>
      </div> */}
      <div className={classes.content}>
        {items?.map(({ id, name, display_name, used }) => {
          const isSelected = selectedItems?.find((i) => i.id === id);
          return (
            <Button
              key={id}
              className={clsx(classes.button, {
                [classes.buttonActive]: isSelected,
                [classes.buttonUnused]: !used,
              })}
              variant="outlined"
              onClick={handleItemClick(id)}
              endIcon={
                isSelected ? (
                  <RemoveIcon
                    fontSize="inherit"
                    className={clsx(
                      classes.buttonIcon,
                      classes.buttonIconActive
                    )}
                  />
                ) : (
                  <AddIcon fontSize="inherit" className={classes.buttonIcon} />
                )
              }
            >
              <Text
                size={12}
                weight="SemiBold"
                className={clsx(classes.buttonLabel, {
                  [classes.buttonLabelActive]: isSelected,
                })}
              >
                {display_name || name}
              </Text>
            </Button>
          );
        })}
      </div>
      <Br height={20 - buttonMargin} />
    </div>
  );
}
