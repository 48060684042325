import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import UnauthenticatedLayout from 'src/layouts/unauthenticated';
import AuthClassicLayout from 'src/layouts/auth/classic';
import AuthModernLayout from 'src/layouts/auth/modern';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordPage = lazy(
  () => import('src/pages/auth/jwt/forgot-password')
);
const JwtVerifyPage = lazy(() => import('src/pages/auth/jwt/verify'));
const JwtPasswordResetPage = lazy(
  () => import('src/pages/auth/jwt/password-reset')
);

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthModernLayout>
          <JwtLoginPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with Minimal">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <UnauthenticatedLayout>
          <Outlet />
        </UnauthenticatedLayout>
      ),
      children: [
        { path: 'forgot-password', element: <JwtForgotPasswordPage /> },
        { path: 'verify', element: <JwtVerifyPage /> },
        { path: 'password-reset', element: <JwtPasswordResetPage /> },
      ],
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
