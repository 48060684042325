import { Button } from '@mui/material';
import CollapseMUI from '@mui/material/Collapse';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  top: {
    margin: 0,
    width: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  content: {
    width: '100%',
  },
}));

export default function Collapse({
  children,
  top,
  contentContainerClassName,
  contentClassName,
  topClassName,
  onCollapse,
  initialCollapse,
  divider,
}) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = React.useState(initialCollapse);

  const handleChange = () => {
    setCollapsed((prev) => {
      if (typeof onCollapse === 'function') {
        onCollapse(!prev);
      }
      return !prev;
    });
  };

  return (
    <div className={classes.root}>
      <Button
        className={clsx(classes.top, topClassName)}
        onClick={handleChange}
      >
        {top}
      </Button>
      {divider}
      <div className={classes.container}>
        <CollapseMUI
          in={!collapsed}
          className={clsx(classes.contentContainer, contentContainerClassName)}
          collapsedSize={0}
        >
          <div className={clsx(classes.content, contentClassName)}>
            {children}
          </div>
        </CollapseMUI>
      </div>
    </div>
  );
}
